/*!*
 * Description: hunabku CSS
 * @author Abraham Martinez <apps@hunabku.com.mx>
 * @creacion  2014/03/01
 * Version: 1.1
 */

 /*= 
---------------------------------------------- */

	/*=> colors */
		.tint1{color:#333}.tint2{color:#777}.tint3{color:#CCC}
		.tintB{color:#000;}
	/*=> fonts:family; */
		.fntN{font-family:"Assistant",sans-serif} 
		.fntB{font-family:"Assistant",sans-serif} 
		.fntL{font-family:"Assistant",sans-serif}
		.fntwB{font-weight:bold;}

	/*=> fonts:sizes; */
	.fnt6{font-size:.375em}.fnt7{font-size:.4375em}.fnt8{font-size:.5em}.fnt9{font-size:.5625em}.fnt10{font-size:.625em}.fnt11{font-size:.6875em}.fnt12{font-size:.75em}.fnt13{font-size:.8125em}.fnt14{font-size:.875em}.fnt15{font-size:.9375em}.fnt16{font-size:1em}.fnt17{font-size:1.0625em}.fnt18{font-size:1.125em}.fnt19{font-size:1.1875em}.fnt20{font-size:1.25em}.fnt21{font-size:1.3125em}.fnt22{font-size:1.375em}.fnt23{font-size:1.4375em}.fnt24{font-size:1.5em}.fnt25{font-size:1.5625em}.fnt26{font-size:1.625em}.fnt27{font-size:1.6875em}.fnt28{font-size:1.75em}.fnt29{font-size:1.8125em}.fnt30{font-size:1.875em}.fnt31{font-size:1.9375em}.fnt32{font-size:2em}.fnt33{font-size:2.0625em}.fnt34{font-size:2.125em}.fnt35{font-size:2.1875em}.fnt36{font-size:2.25em}.fnt37{font-size:2.3125em}.fnt38{font-size:2.375em}.fnt39{font-size:2.4375em}.fnt40{font-size:2.5em}.fnt41{font-size:2.5625em}.fnt42{font-size:2.625em}.fnt43{font-size:2.6875em}.fnt44{font-size:2.75em}.fnt45{font-size:2.8125em}.fnt46{font-size:2.875em}.fnt47{font-size:2.9375em}.fnt48{font-size:3em}.fnt49{font-size:3.0625em}.fnt50{font-size:3.125em}.fnt51{font-size:3.1875em}.fnt52{font-size:3.25em}.fnt53{font-size:3.3125em}.fnt54{font-size:3.375em}.fnt55{font-size:3.4375em}.fnt56{font-size:3.5em}.fnt57{font-size:3.5625em}.fnt58{font-size:3.625em}
	/*=> spaces:interlines; */
	.sp1x,.sp2x,.sp3x,.sp4x,.sp5x,.sp6x,.sp7x,.sp8x,.sp9x,.sp10x,.sp20x,.sp30x,.sp40x,.sp50x,.sp60x{content:"&nbsp;";display:block;width:100%;font-size:inherit;height:0;border:none;border:0;padding:0px;margin:0px;}

	/*@media ( min-width: 478px ){*/.sp1x{height:.09375em}.sp2x{height:.1875em}.sp3x{height:.28125em}.sp4x{height:.375em}.sp5x{height:.46875em}.sp6x{height:.5625em}.sp7x{height:.65625em}.sp8x{height:.75em}.sp9x{height:.84375em}.sp10x{height:.9375em}.sp20x{height:1.875em}.sp30x{height:2.8125em}.sp40x{height:3.75em}.sp50x{height:4.6875em}.sp60x{height:5.625em}/*}*/
	@media ( min-width: 768px ){.sp1x{height:.1875em}.sp2x{height:.375em}.sp3x{height:.5625em}.sp4x{height:.75em}.sp5x{height:.9375em}.sp6x{height:1.125em}.sp7x{height:1.3125em}.sp8x{height:1.5em}.sp9x{height:1.6875em}.sp10x{height:1.875em}.sp20x{height:3.75em}.sp30x{height:5.625em}.sp40x{height:7.5em}.sp50x{height:9.375em}.sp60x{height:11.25em}}
	@media ( min-width: 992px ){.sp1x{height:.25em}.sp2x{height:.5em}.sp3x{height:.75em}.sp4x{height:1em}.sp5x{height:1.25em}.sp6x{height:1.5em}.sp7x{height:1.75em}.sp8x{height:2em}.sp9x{height:2.25em}.sp10x{height:2.5em}.sp20x{height:5em}.sp30x{height:7.5em}.sp40x{height:10em}.sp50x{height:12.5em}.sp60x{height:15em}}
	@media ( min-width: 1200px ){.sp1x{height:.34375em}.sp2x{height:.6875em}.sp3x{height:1.03125em}.sp4x{height:1.375em}.sp5x{height:1.71875em}.sp6x{height:2.0625em}.sp7x{height:2.40625em}.sp8x{height:2.75em}.sp9x{height:3.09375em}.sp10x{height:3.4375em}.sp20x{height:6.875em}.sp30x{height:10.3125em}.sp40x{height:13.75em}.sp50x{height:17.1875em}.sp60x{height:20.625em}}
		/*=> hr */
	hr{display:inline-block;margin:0;padding:0;width:100%;height:1px;line-height:100%;border-top:1px solid #c4c5c9;margin-bottom:4px;margin-top:3px;*margin-top:0}
		/*=> wrappers full-witdh */
	.wp-body{background-color:transparent;display:block;height:auto;left:0;margin:0;padding:0 0 0 0;text-align:center;top:0;width:100%;position:relative;z-index:1;}
	.wp-body .wp-pager{height:auto;margin:0 auto 0 auto;padding:0;position:relative;text-align:left;width:100%;}
	.wp-body .wp-pager .wp-main{height:auto;margin:0 auto 0 auto;padding:0;position:relative;text-align:left;width:100%;}
		/*=> wrappers: bg colors */
	.wp-body.wp-color{background-color:black;}
		/*=> loaders */
	/*#loader{display:none;position:absolute;z-index:100;width:100%;height:100%;text-align:center;background:url("../img/preloader_wp.png") 0 0 scroll transparent;} #loader .loader_bg{position:absolute;left:50%;top:30%;margin:-18px 0 0 -18px;padding:0;width:36px;height:36px;line-height:54px;text-align:center;background:url("../img/preloader_bg.png") 0 0 no-repeat scroll transparent;} #loader .loader_bg .loader_obj{margin:0;padding:0;width:100%;height:100%;background:url("../img/preloader.gif") center center no-repeat scroll transparent} */
		/*=> links */
	a,a:focus{outline:0;border:0;}
	a{color:#343434;text-decoration:none;}a:hover,a:focus{color:#141414;text-decoration:none;}
	a.anchor-screen{display:block;visibility:hidden;height:0;left:50%;position:relative;top:0;width:0;overflow:hidden;z-index:-100000}
	a[href^=tel],a.phone{color:#5e5e5e;font-weight:normal;text-decoration:none;} a[href^=tel]:hover,a.phone:hover{color:#5e5e5e;font-weight:normal;text-decoration:none}

		/*=> links */
			a.link-footer{
				color:#FFF;
				text-decoration: none;
			}
			a.link-footer:hover{
				text-decoration: underline;
			}
			a.link.link-login{
				padding: .5em 0;
				font-size: .9em;
				color: #414141;
			}
		/*=> buttons */
			.button{
				color: #343434;
				border: 0px solid #343434;
				font-weight: bold;
				font-size: 0.8em;
				padding: 1em 1.8em 1em 1.8em;
				margin: 0.65em 0em;
				display:inline-block;*display:inline;*zoom:1;
				border-radius: 2em 2em 2em 2em;
				-moz-border-radius: 2em 2em 2em 2em;
				-webkit-border-radius: 2em 2em 2em 2em;
				background-color: #fff
			}
			.button span{
				padding: 1em 0em 1em 0em;
				border-radius: 2em 2em 2em 2em;
				-moz-border-radius: 2em 2em 2em 2em;
				-webkit-border-radius: 2em 2em 2em 2em;
			}
			.button:hover{
				border:0px solid #141414;
				color:#141414;
				background-color: #CCC
			}
			.button-outline{
				color: #343434;
				border: 1px solid #343434;
				font-weight: bold;
				font-size: 0.8em;
				padding: 1em 1.8em 1em 1.8em;
				margin: 0.65em 0em;
				display:inline-block;*display:inline;*zoom:1;
				border-radius: 2em 2em 2em 2em;
				-moz-border-radius: 2em 2em 2em 2em;
				-webkit-border-radius: 2em 2em 2em 2em;
				background-color: transparent
			}
			.button-outline span{
				padding: 1em 0em 1em 0em;
				border-radius: 2em 2em 2em 2em;
				-moz-border-radius: 2em 2em 2em 2em;
				-webkit-border-radius: 2em 2em 2em 2em;
			}
			.button-outline:hover{
				border:1px solid #141414;
				color:#141414;
				background-color: transparent
			}
			.button-square{
				border-radius: 0em 0em 0em 0em;
				-moz-border-radius: 0em 0em 0em 0em;
				-webkit-border-radius: 0em 0em 0em 0em;
			}
			.button-square span{
				border-radius: 0em 0em 0em 0em;
				-moz-border-radius: 0em 0em 0em 0em;
				-webkit-border-radius: 0em 0em 0em 0em;
			}
			.button.button-large{
				padding: 1em 2.3em 1em 2.3em;
			}
			.button.button-sm{
				padding: 0.8em 2.3em 0.9em 2.3em;
				}
				.button.button-sm span{
					padding: 0.8em 0em 0.9em 0em;
				}
			.button-outline.button-sm{
				padding: 0.8em 2.3em 0.9em 2.3em;
				}
				.button-outline.button-sm span{
					padding: 0.8em 0em 0.9em 0em;
				}
			.button.button-md{
				padding: 1em 1.7em 1.1em 1.7em;
				border-radius: 2.5em 2.5em 2.5em 2.5em;
				-moz-border-radius: 2.5em 2.5em 2.5em 2.5em;
				-webkit-border-radius: 2.5em 2.5em 2.5em 2.5em;
				}
				.button.button-md span{
					border-radius: 2.5em 2.5em 2.5em 2.5em;
					-moz-border-radius: 2.5em 2.5em 2.5em 2.5em;
					-webkit-border-radius: 2.5em 2.5em 2.5em 2.5em;
				}
			.button.button-lg{
				padding: 1.2em 2.7em 1.1em 2.7em;
				border-radius: 3em 3em 3em 3em;
				-moz-border-radius: 3em 3em 3em 3em;
				-webkit-border-radius: 3em 3em 3em 3em;
				}
				.button.button-lg span{
					padding: 1.2em 0em 1.1em 0em;
					border-radius: 3em 3em 3em 3em;
					-moz-border-radius: 3em 3em 3em 3em;
					-webkit-border-radius: 3em 3em 3em 3em;
				}
			.button.button-sm-large{
				padding: 0.25em 4.5em 0.35em 4.5em;
			}
			.button.button-md-large{
				padding: 1em 4.5em 1.1em 4.5em;
			}
			@media (min-width: 995px){
				.button{  
					font-size: 0.9em;
					padding: 1.2em 2em 1.1em 2em;
					}
					.button span{  
						padding: 1.2em 0em 1.1em 0em;
					}
				.button-outline{  
					font-size: 0.9em;
					padding: 1.2em 2em 1.1em 2em;
					}
					.button-outline span{  
						padding: 1.2em 0em 1.1em 0em;
					}
			}
			@media (min-width: 768px){
				.button.button-sm{
					padding: 0.3em 2em 0.4em 2em;
					}
					.button.button-sm span{
						padding: 0.3em 0em 0.4em 0em;
					}
				.button-outline.button-sm{
					padding: 0.3em 2em 0.4em 2em;
					}
					.button-outline.button-sm span{
						padding: 0.15em 0em 0.25em 0em;
					}
				.button.button-lg{
					padding: 1.6em 3.7em 1.7em 3.7em
				}
				.button.button-lg span{
					padding: 1.6em 0em 1.7em 0em
				}
			}

		/*=> navs/list */
			.bar{margin-bottom:0;padding-left:0;list-style:none;} .bar:before,.bar:after{content:" ";display:table} .bar:after{clear:both} .bar > li{position:relative;display:block;} .bar > li > a{position:relative;display:block;padding:0;} .bar > li > a:hover,.bar > li > a:focus{text-decoration:none}
			.list{margin-bottom:0;padding-left:0;list-style:none;} .list:before,.list:after{content:" ";display:table} .list:after{clear:both} .list > li{position:relative;display:block;} .list > li > a{position:relative;display:block;padding:0;} .list > li > a:hover,.list > li > a:focus{text-decoration:none}
			/*=> IE */
		/*=> texts */
			.toUppercase{text-transform: uppercase;}
			.text-justify{text-align:justify;text-justify:inter-word;-moz-text-align-last:left;text-align-last:left}
	/*= chrome frame IE */
		.chromeframe{background:Yellow;display:inline-block;margin:0 auto;padding:.65em 0%;position:absolute;width:100%;text-align:center;z-index:10000;*zoom:1;*display:inline} .chromeframe p{color:#f00;font-size:1.355em;padding:0% 10%;text-align:center;width:100%}
		html.browser-ie img{width:auto/9;height:auto}
	/* .line-height */
		.lineH-100p{line-height:100%;}
		.lineH-120p{line-height:120%;}
		.lineH-90p{line-height:90%;}
		.lineH-70p{line-height:70%;}
	/* no-margin */
		.no-margin{margin:0;}	.no-marginT{margin-top:0;}	.no-marginB{margin-bottom:0;}
		.no-paddingT{padding-top:0;}	.no-paddingB{padding-bottom:0;}
		.border-top-0{border-top:0px;} .border-right-0{border-right:0px;} .border-bottom-0{border-bottom:0px;} .border-left-0{border-left:0px;}
	
	/* forms */
	::-webkit-input-placeholder{color: #07829b}
	::-moz-placeholder{color: #07829b}
	:-moz-placeholder{color: #07829b}
	:-ms-input-placeholder{color: #07829b}
	input.form-control{
		background-color: transparent;
		border: 0px;
		border-bottom: 2px solid #4b5da7;
		box-shadow: inset 0 0 0;
		border-radius: 0px;
		margin-bottom: 1em; 
	}	
	
	input.form-control:focus, button.form-control:focus {
		background-color: transparent;
	}

	input.form-control.error{
		color: red;
		border-bottom: 2px solid red;
	}

/* movil mmenu */
	#mmenu-open{box-sizing:border-box;display:block;width:40px;height:40px;position:fixed;top:0.35em;left:5px;z-index:9999}#mmenu-open:before,#mmenu-open:after,#mmenu-open span{background:#17286d;content:'';display:block;width:30px;height:5px;position:absolute;left:4px;
					-webkit-box-shadow: 0px 0px 1px 1px rgba(255,255,255,0.7);
					-moz-box-shadow: 0px 0px 1px 1px rgba(255,255,255,0.7);
					box-shadow: 0px 0px 1px 1px rgba(255,255,255,0.7);
					}
	#mmenu-open:before{top:7px}
	#mmenu-open span{top:19px}
	#mmenu-open:after{top:30px}
	#mmenu-open:before,
	#mmenu-open:after,
	#mmenu-open span{-webkit-transition:none .5s ease .3s;transition:none .5s ease .3s;-webkit-transition-property:transform,top,bottom,left,opacity;transition-property:transform,top,bottom,left,opacity}
	html.mm-opening #mmenu-open:before,html.mm-opening #mmenu-open:after{top:20px}html.mm-opening #mmenu-open span{left:-50px;opacity:0}html.mm-opening #mmenu-open:before{transform:rotate(45deg)}
	html.mm-opening #mmenu-open:after{transform:rotate(-45deg)}

	#mmenu ul li.li-social {
		padding: 0; }

	#mmenu ul li.li-social span.social {
		padding: 0 1em; }

	#mmenu ul li.li-social span.social span.item {
		float: left;
		padding: 0 0.35em;
		display: inline-block;
		width: 32px;
		height: 32px;
		position: relative;
		top: 0%;
		margin-top: 0px; }

	#mmenu ul li.li-social span.social span.item a.ta {
		background-image: url(../img/icon-tripadvisor_32x32.png); }

	#mmenu ul li.li-social span.social span.item a.fb {
		background-image: url(../img/icon-facebook_32x32.png); }

	#mmenu ul li.li-social span.social span.item a.tw {
		background-image: url(../img/icon-twitter_32x32.png); }

	#mmenu ul li.li-social span.social span.item a.it {
		background-image: url(../img/icon-instagram_32x32.png); }

	#mmenu ul li.li-social span.social span.item a {
		opacity: 1;
		display: inline-block;
		width: 32px;
		height: 32px;
		background-repeat: no-repeat;
		background-position: center -32px; }

	#mmenu ul li.li-social span.social span.item a span {
		display: none; }

	#mmenu ul li.li-social span.social span.item a:hover {
		/*background-position: center 0px;*/ }

	#mmenu a{
		olor: #17286d;
	}

/* loader css */
	@-webkit-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-webkit-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-moz-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-ms-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-moz-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-webkit-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@-o-keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	@keyframes uil-ripple {
		0% {
			width: 0;
			height: 0;
			opacity: 0;
			margin: 0 0 0 0;
		}
		33% {
			width: 44%;
			height: 44%;
			margin: -22% 0 0 -22%;
			opacity: 1;
		}
		100% {
			width: 88%;
			height: 88%;
			margin: -44% 0 0 -44%;
			opacity: 0;
		}
	}
	
	.loader-ws{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		text-align: center;
		margin: 0 auto;
		background-color: rgba(255,255,255,0.95);
		z-index: 999;
	}
	.loader-waves {
		background: none;
		position: relative;
		left: 50%;
		top: 50%;
		margin-left: -100px;
		margin-top: -100px;
		width: 200px;
		height: 200px;
	}
	.loader-waves div {
		position: absolute;
		top: 50%;
		left: 50%;
		margin: 0;
		width: 0;
		height: 0;
		opacity: 0;
		border-radius: 50%;
		border-width: 18px;
		border-style: solid;
		-ms-animation: uil-ripple 1s ease-out infinite;
		-moz-animation: uil-ripple 1s ease-out infinite;
		-webkit-animation: uil-ripple 1s ease-out infinite;
		-o-animation: uil-ripple 1s ease-out infinite;
		animation: uil-ripple 1s ease-out infinite;
	}
	.loader-waves div:nth-of-type(1) {
		border-color: #17286d;
	}
	.loader-waves div:nth-of-type(2) {
		border-color: #fee600;
		-ms-animation-delay: 0.5s;
		-moz-animation-delay: 0.5s;
		-webkit-animation-delay: 0.5s;
		-o-animation-delay: 0.5s;
		animation-delay: 0.5s;
	}

/* ... css */
