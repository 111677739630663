.modalPack{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 90%;
    max-width: 450px;
    background: #FDE434;
    transition: .5s;
    z-index: -10;
    border-radius: 15px;
}

.spanClose{
    position: absolute;
    top: 0;
    right: -50px;
    background-color: #17286d;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: .5s;
    opacity: 1;
}

.spanClose:hover{
    cursor: pointer;
    opacity: .5;
}

.logoSection{
    width: 80%;
    margin: 2em auto;
    max-width: 300px;
    text-align: center;
}

.logoSection img{
    width: 100%;
    display: block;
}

.containerNameBlue{
    font-family: Assistant, Arial, sans-serif;
    color: #FFF;
    background: #1A2B6A;
    font-size: 20px;
    padding: 10px 0;
    text-align: center;
    text-transform: uppercase;

}

.container-price-modal{
    font-size: 0px;
    font-family: Assistant, Arial, sans-serif;
    text-align: center;
}

.container-price-modal span{
    display: inline-block;
    width: 48%;
    text-align: center;
    color: #1A2B6A;
    font-size: 20px;
    margin: 15px 0;
    padding: 10px 0;
}

.container-price-modal span:nth-child(1){
    border-right: 2px solid #1A2B6A;
}

.textDescriptionIsland{
    width: 90%;
    max-width: 820px;
    margin: 0 auto;
    margin-bottom: 2em;
    
}

.textDescriptionIsland p{
    color: #fff;
    font-size: 1em;
    display: block;
    margin-bottom: 1em;
    display: block;
}

.sectionFromAbs{
    position: absolute !important;
    top: -35px;
    left: -1px;
    padding: 5px 15px;
    width: auto !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-color: #fee600 !important;
    color: #17286C !important;
    border: 1px solid #FFF;
}

.island_express #hero.wp-body {
    background-position: center 0em !important;
}
