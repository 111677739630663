/* hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 200;
  src: local('Assistant ExtraLight'), local('Assistant-ExtraLight'), url(https://fonts.gstatic.com/s/assistant/v1/xXstfiHQzjB9j5ZxYTBoZ_ZjSN9jRjHSPidsMdAc5jQ.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 200;
  src: local('Assistant ExtraLight'), local('Assistant-ExtraLight'), url(https://fonts.gstatic.com/s/assistant/v1/xXstfiHQzjB9j5ZxYTBoZxampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: local('Assistant'), local('Assistant-Regular'), url(https://fonts.gstatic.com/s/assistant/v1/Fur5L4pc_qxx9mG3n62Yj4X0hVgzZQUfRDuZrPvH3D8.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: local('Assistant'), local('Assistant-Regular'), url(https://fonts.gstatic.com/s/assistant/v1/Uc4viXlMMsk3-slnOukfHJBw1xU1rKptJj_0jans920.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* hebrew */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 800;
  src: local('Assistant ExtraBold'), local('Assistant-ExtraBold'), url(https://fonts.gstatic.com/s/assistant/v1/-mTR0sX8a0RsadH4AMDT8PZjSN9jRjHSPidsMdAc5jQ.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}
/* latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 800;
  src: local('Assistant ExtraBold'), local('Assistant-ExtraBold'), url(https://fonts.gstatic.com/s/assistant/v1/-mTR0sX8a0RsadH4AMDT8Bampu5_7CjHW5spxoeN3Vs.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}