
.container-pack-hours{
    width: 80%;
    margin-left: 10%;
    max-width: 220px;
    font-size: 0px;
}

.time-traveling{
    width: 50%;
    margin: 0%;
    display: inline-block;
    font-size: 16px;
    padding: 5px;
    box-sizing: border-box;
    vertical-align: top;
}

.time-traveling .info-pack{
    font-size: 1em;
    text-align: center;
    border: 1px solid #FFF;
    box-sizing: border-box;
}

.info-pack .header-pack{
    display: block;
    width: 100%;
    line-height: 1.1em;
    padding: 5px 0;
    font-weight: bold;
}

.type-color-001{
    background-color: #17286d;
    color: #FFF;
}

.type-color-002{
    background-color: #07829b;
    color: #FFF;
}

.list-hours, .list-hours span{
    width: 100%; 
    display: block;
    font-family: "Assistant",sans-serif;
    color: #17286d;
}

.list-style-001 span:nth-child(2n-1){
    background-color: #fee600;
}

.list-style-001 span:nth-child(2n){
    background-color: #fff;
}

.list-style-002 span:nth-child(2n-1){
    background-color: #17286d;
    color: #FFF;
}

.list-style-002 span:nth-child(2n){
    background-color: #fff;
}


.element-hours{
    vertical-align: top;
    display: inline-block;
    width: 49% !important;
}

.element-hours li, .element-hours ul, .element-hours div {
    display: block;
    width: 100% !important;
}

.blue-second-color span.bg.dw {
  background-color: #2290a6 !important;
}

.blue-second-color span.tx.dw{
  color: #FFF !important;
}

.container-packs{
    margin-left: 1.5em;
}

@media(max-width: 992px){
    .container-packs{
        margin-left: 1em;
    }
}

@media(max-width: 768px){
    .container-packs{
        margin-left: 5%;
    }
}

