/*!*
 * Description: General CSS
 * @author Abraham Martinez <apps@hunabku.com.mx>
 * @creacion  2016/09/20
 * Version: 1.0
 */
/*=> background-image */
/*=> transition */
/*=> Animation */
/*=> KeyFrames fn*/
/*=> link_Fx_t1 */

@keyframes kf_navUp {
  0% {
    top: 0px;
    opacity: 0; }
  100% {
    top: 0px;
    opacity: 1; } }

@keyframes kf_navUp_hover {
  0% {
    top: 0px;
    opacity: 1; }
  100% {
    top: 0px;
    opacity: 0; } }

@keyframes kf_navDw_over {
  0% {
    bottom: 0px;
    opacity: 0; }
  100% {
    bottom: 0px;
    opacity: 1; } }

@keyframes kf_navbg {
  0% {
    width: 100%;
    opacity: 1;
    left: 0%; }
  100% {
    width: 0%;
    opacity: 0;
    left: 50%; } }

@keyframes kf_navbg_over {
  0% {
    width: 0%;
    opacity: 0;
    left: 50%; }
  100% {
    width: 100%;
    opacity: 1;
    left: 0%; } }

@keyframes kf_navbg_active {
  0% {
    width: 100%;
    opacity: 1;
    left: 0%; }
  100% {
    width: 100%;
    opacity: 1;
    left: 0%; } }

/*=> buttonFx_button */

@keyframes kf_button_push_3px {
  0% {
    margin-left: 0; }
  100% {
    margin-left: 3px; } }

@keyframes kf_button_pull_3px {
  0% {
    margin-left: 3px; }
  100% {
    margin-left: 0; } }

@keyframes kf_button_animation-yellow-white {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

/*=> buttonFx_buttonOutline */

@keyframes kf_button_push_3px {
  0% {
    margin-left: 0; }
  100% {
    margin-left: 3px; } }

@keyframes kf_button_pull_3px {
  0% {
    margin-left: 3px; }
  100% {
    margin-left: 0; } }

@keyframes kf_button_animation-yellow-white {
  0% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

/*=> btn_image_and_text */
/*=> btn_image */
/*=> btn_icon */
/*=> modalFx */

@keyframes kf_modalFx_push {
  0% {
    opacity: 0;
    left: -16px; }
  100% {
    opacity: 1;
    left: 0px; } }

/*=> fadeInFx */
/*=> pushFx */

@keyframes kf_pushFx_up {
  0% {
    opacity: 0;
    top: -100%; }
  100% {
    opacity: 1;
    top: 0px; } }

@keyframes kf_pushFx_right {
  0% {
    opacity: 0;
    left: 100%; }
  100% {
    opacity: 1;
    left: 0; } }

@keyframes kf_pushFx_down {
  0% {
    opacity: 0;
    bottom: -100%; }
  100% {
    opacity: 1;
    bottom: 0; } }

@keyframes kf_pushFx_left {
  0% {
    opacity: 0;
    left: -100%; }
  100% {
    opacity: 1;
    left: 0px; } }

/*=> pullFx */

@keyframes kf_pullFx_right {
  0% {
    opacity: 1;
    left: 0%; }
  100% {
    opacity: 0;
    left: 100%; } }

/*=> ToggleFx */

@keyframes kf_toggleFx_down {
  0% {
    height: 0%;
    top: 0; }
  100% {
    height: 100%;
    top: 0; } }

@keyframes kf_toggleFx_up {
  0% {
    height: 100%;
    top: 0; }
  100% {
    height: 0%;
    top: 0; } }

.itemli3:hover {
  cursor: pointer; }
  .itemli3:hover img:nth-child(1) {
    display: none !important; }
  .itemli3:hover img:nth-child(2) {
    display: block !important; }

.image-button img:nth-child(2) {
  display: none !important; }

.btn-2-el {
  padding: 0.25em 1.5em 0.25em 1.5em !important;
  font-size: 1.1 !important;
  font-weight: bold !important;
  border-radius: 20px !important;
  margin: 0.65em 0em; }

.btn-packs {
  width: 180px;
  text-align: center; }

.btn-2-el a,
.btn-2-el span {
  display: block;
  width: 100%; }

.btn-2-el:hover {
  cursor: pointer; }

.color-yellow-btn {
  background-color: #fee600 !important;
  transition: .5s; }
  .color-yellow-btn span a {
    color: #17286C !important; }

.color-yellow-btn:hover {
  cursor: pointer;
  background-color: #FFF !important; }

.color-blue-btn {
  background-color: #2290a6 !important;
  background-image: url(../img/island_express-icon_ship_auto.png) !important;
  background-repeat: no-repeat !important;
  background-position-x: 85%;
  background-position-y: 40%;
  background-size: 40%;
  transition: .5s; }
  .color-blue-btn span a {
    color: #FFF !important;
    padding-right: 5.5em !important; }

.color-blue-btn:hover {
  background-image: url(../img/island_express-icon_ship_auto_turq.png) !important; }

.color-blue-btn:hover {
  cursor: pointer;
  background-color: #FFF !important; }
  .color-blue-btn:hover span a {
    color: #2290a6 !important; }

#header > div > div > div > div.row > div > div.nav_right > nav.nav_up > ul > li.li-innovation.btn-icon-button > a {
  position: relative !important;
  background-image: url(../img/island_express-icon_ship_auto.png) !important;
  background-repeat: no-repeat !important;
  background-position-x: 90%;
  background-position-y: 40%;
  background-size: 40%; }

.btn-icon-button span.image-button {
  position: absolute;
  top: 25%;
  right: 7%;
  width: 4em;
  z-index: 5;
  display: block; }

.btn-icon-button span.image-button img {
  width: 100%;
  display: block;
  cursor: pointer; }

.btn-icon-button > a {
  padding-right: 4em; }

li.li-innovation.btn-icon-button > a > span.tx.dw {
  padding-right: 5em !important;
  cursor: pointer; }

/*============================================= */
/*==|== primary styles ===== ================== */
/*============================================= */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

html, body {
  background-color: #FFFFFF;
  overflow-x: hidden;
  overflow-y: visible; }

html.browser-ie, html.browser-ie body {
  overflow-x: visible; }

/*!* == @Icons & Buttons ===== ======== */
/*===================================== */
/*=> buttons */
.button.button-gray {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #FFFFFF; }
  .button.button-gray span {
    display: block;
    position: absolute; }
  .button.button-gray span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.button-gray span.tx.up {
    z-index: 4; }
  .button.button-gray span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.button-gray span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.button-gray span.bg.dw {
    z-index: 3; }
  .button.button-gray span.tx.up {
    color: #17286d;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button.button-gray span.tx.dw {
    opacity: 1;
    color: #17286d;
    transition: opacity 0.5s ease-in 0.5s; }
  .button.button-gray span.bg.up {
    opacity: 0;
    background-color: #fee600;
    transition: opacity 0.5s ease-out; }
  .button.button-gray span.bg.dw {
    opacity: 1;
    background-color: #FFFFFF;
    transition: opacity 0s ease-in 0.5s; }
  .button.button-gray:hover {
    background-color: #fee600; }
    .button.button-gray:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button.button-gray:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button.button-gray:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button.button-gray:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button.button-yellow-blue {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fee600; }
  .button.button-yellow-blue span {
    display: block;
    position: absolute; }
  .button.button-yellow-blue span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.button-yellow-blue span.tx.up {
    z-index: 4; }
  .button.button-yellow-blue span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.button-yellow-blue span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.button-yellow-blue span.bg.dw {
    z-index: 3; }
  .button.button-yellow-blue span.tx.up {
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button.button-yellow-blue span.tx.dw {
    opacity: 1;
    color: #17286d;
    transition: opacity 0.5s ease-in 0.5s; }
  .button.button-yellow-blue span.bg.up {
    opacity: 0;
    background-color: #17286d;
    transition: opacity 0.5s ease-out; }
  .button.button-yellow-blue span.bg.dw {
    opacity: 1;
    background-color: #fee600;
    transition: opacity 0s ease-in 0.5s; }
  .button.button-yellow-blue:hover {
    background-color: #17286d; }
    .button.button-yellow-blue:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button.button-yellow-blue:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0s ease-out 0.5s; }
    .button.button-yellow-blue:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button.button-yellow-blue:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button.button-yellow-white {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #fee600; }
  .button.button-yellow-white span {
    display: block;
    position: absolute; }
  .button.button-yellow-white span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.button-yellow-white span.tx.up {
    z-index: 4; }
  .button.button-yellow-white span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.button-yellow-white span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.button-yellow-white span.bg.dw {
    z-index: 3; }
  .button.button-yellow-white span.tx.up {
    color: #17286d;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button.button-yellow-white span.tx.dw {
    opacity: 1;
    color: #17286d;
    transition: opacity 0.5s ease-in 0.5s; }
  .button.button-yellow-white span.bg.up {
    opacity: 0;
    background-color: #FFFFFF;
    transition: opacity 0.5s ease-out; }
  .button.button-yellow-white span.bg.dw {
    opacity: 1;
    background-color: #fee600;
    transition: opacity 0s ease-in 0.5s; }
  .button.button-yellow-white:hover {
    background-color: #FFFFFF; }
    .button.button-yellow-white:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button.button-yellow-white:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button.button-yellow-white:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button.button-yellow-white:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button.button-animation-yellow-white {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #FFFFFF; }
  .button.button-animation-yellow-white span {
    display: block;
    position: absolute; }
  .button.button-animation-yellow-white span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.button-animation-yellow-white span.tx.up {
    z-index: 4; }
  .button.button-animation-yellow-white span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.button-animation-yellow-white span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.button-animation-yellow-white span.bg.dw {
    z-index: 3; }
  .button.button-animation-yellow-white span.tx.up {
    color: #17286d;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button.button-animation-yellow-white span.tx.dw {
    opacity: 1;
    color: #17286d;
    transition: opacity 0.5s ease-in 0.5s; }
  .button.button-animation-yellow-white span.bg.up {
    opacity: 0;
    background-color: #FFFFFF;
    transition: opacity 0.5s ease-out; }
  .button.button-animation-yellow-white span.bg.dw {
    opacity: 1;
    background-color: #fee600;
    animation: kf_button_animation-yellow-white 1s ease-in-out infinite; }
  .button.button-animation-yellow-white:hover {
    background-color: #FFFFFF; }
    .button.button-animation-yellow-white:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button.button-animation-yellow-white:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button.button-animation-yellow-white:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button.button-animation-yellow-white:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button.button-blue-green {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #17286d; }
  .button.button-blue-green span {
    display: block;
    position: absolute; }
  .button.button-blue-green span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.button-blue-green span.tx.up {
    z-index: 4; }
  .button.button-blue-green span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.button-blue-green span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.button-blue-green span.bg.dw {
    z-index: 3; }
  .button.button-blue-green span.tx.up {
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button.button-blue-green span.tx.dw {
    opacity: 1;
    color: #FFFFFF;
    transition: opacity 0.5s ease-in 0.5s; }
  .button.button-blue-green span.bg.up {
    opacity: 0;
    background-color: #08829a;
    transition: opacity 0.5s ease-out; }
  .button.button-blue-green span.bg.dw {
    opacity: 1;
    background-color: #17286d;
    transition: opacity 0s ease-in 0.5s; }
  .button.button-blue-green:hover {
    background-color: #08829a; }
    .button.button-blue-green:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button.button-blue-green:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button.button-blue-green:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button.button-blue-green:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button.input-yellow-blue {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #17286d;
  background-color: #fee600;
  transition: background-color 0.6s ease-in-out; }
  .button.input-yellow-blue span {
    display: block;
    position: absolute; }
  .button.input-yellow-blue span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.input-yellow-blue span.tx.up {
    z-index: 4; }
  .button.input-yellow-blue span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.input-yellow-blue span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.input-yellow-blue span.bg.dw {
    z-index: 3; }
  .button.input-yellow-blue:hover {
    color: #FFFFFF;
    background-color: #17286d;
    transition: background-color 0.6s ease-in-out; }

.button.input-yellow-white {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  color: #17286d;
  background-color: #fee600;
  transition: background-color 0.6s ease-in-out; }
  .button.input-yellow-white span {
    display: block;
    position: absolute; }
  .button.input-yellow-white span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button.input-yellow-white span.tx.up {
    z-index: 4; }
  .button.input-yellow-white span.tx.dw {
    position: relative;
    z-index: 5; }
  .button.input-yellow-white span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button.input-yellow-white span.bg.dw {
    z-index: 3; }
  .button.input-yellow-white:hover {
    color: #17286d;
    background-color: #FFFFFF;
    transition: background-color 0.6s ease-in-out; }

.button-outline.button-yellow-white {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #fee600; }
  .button-outline.button-yellow-white span {
    display: block;
    position: absolute; }
  .button-outline.button-yellow-white span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button-outline.button-yellow-white span.tx.up {
    z-index: 4; }
  .button-outline.button-yellow-white span.tx.dw {
    position: relative;
    z-index: 5; }
  .button-outline.button-yellow-white span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button-outline.button-yellow-white span.bg.dw {
    z-index: 3; }
  .button-outline.button-yellow-white span.tx.up {
    color: #FFFFFF;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button-outline.button-yellow-white span.tx.dw {
    opacity: 1;
    color: #fee600;
    transition: opacity 0.5s ease-in 0.5s; }
  .button-outline.button-yellow-white span.bg.up {
    opacity: 0;
    border-color: #FFFFFF;
    transition: opacity 0.5s ease-out; }
  .button-outline.button-yellow-white span.bg.dw {
    opacity: 1;
    border-color: #fee600;
    transition: opacity 0s ease-in 0.5s; }
  .button-outline.button-yellow-white:hover {
    border-color: #FFFFFF; }
    .button-outline.button-yellow-white:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button-outline.button-yellow-white:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button-outline.button-yellow-white:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button-outline.button-yellow-white:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.button-outline.button-white-yellow {
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  border-color: #FFFFFF; }
  .button-outline.button-white-yellow span {
    display: block;
    position: absolute; }
  .button-outline.button-white-yellow span.bg {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .button-outline.button-white-yellow span.tx.up {
    z-index: 4; }
  .button-outline.button-white-yellow span.tx.dw {
    position: relative;
    z-index: 5; }
  .button-outline.button-white-yellow span.bg.up {
    opacity: 1;
    z-index: 2; }
  .button-outline.button-white-yellow span.bg.dw {
    z-index: 3; }
  .button-outline.button-white-yellow span.tx.up {
    color: #fee600;
    opacity: 0;
    transition: opacity 0.5s ease-out;
    margin-left: 3px;
    animation: kf_button_push_3px 0.35s ease-out; }
  .button-outline.button-white-yellow span.tx.dw {
    opacity: 1;
    color: #FFFFFF;
    transition: opacity 0.5s ease-in 0.5s; }
  .button-outline.button-white-yellow span.bg.up {
    opacity: 0;
    border-color: #fee600;
    transition: opacity 0.5s ease-out; }
  .button-outline.button-white-yellow span.bg.dw {
    opacity: 1;
    border-color: #FFFFFF;
    transition: opacity 0s ease-in 0.5s; }
  .button-outline.button-white-yellow:hover {
    border-color: #fee600; }
    .button-outline.button-white-yellow:hover span.tx.up {
      opacity: 1;
      transition: opacity 0.5s ease-in 0.5s;
      margin-left: 0px;
      animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
    .button-outline.button-white-yellow:hover span.tx.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }
    .button-outline.button-white-yellow:hover span.bg.up {
      opacity: 1;
      transition: opacity 0s ease-in 0.5s; }
    .button-outline.button-white-yellow:hover span.bg.dw {
      opacity: 0;
      transition: opacity 0.5s ease-out; }

.linkFx-blue-green-season {
  display: block;
  font-size: 0.9em;
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #2290a6; }
  .linkFx-blue-green-season span.bg {
    width: 0%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 50%;
    opacity: 0;
    background-color: #17286d;
    animation: kf_navbg 0.5s ease-in; }
  .linkFx-blue-green-season span.dw {
    color: #FFFFFF;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    display: block;
    width: 100%;
    position: relative;
    z-index: 2;
    opacity: 0;
    bottom: 0px;
    transition: opacity 0.3s ease-in; }
  .linkFx-blue-green-season span.up {
    color: #FFFFFF;
    padding-top: 0.6em;
    padding-bottom: 0.6em;
    display: block;
    width: 100%;
    position: absolute;
    z-index: 3;
    opacity: 1;
    top: 0px;
    animation: kf_navUp 0.3s ease-in; }
  .linkFx-blue-green-season:hover span.bg {
    opacity: 1;
    left: 0%;
    width: 100%;
    animation: kf_navbg_over 0.5s ease-out; }
  .linkFx-blue-green-season:hover span.dw {
    opacity: 1;
    bottom: 0;
    animation: kf_navDw_over 0.3s ease-in; }
  .linkFx-blue-green-season:hover span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navUp_hover 0.3s ease-out; }
  .linkFx-blue-green-season span.up span {
    background-image: url(../img/navbar-link_arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding-right: 1em; }
  .linkFx-blue-green-season span.dw span {
    background-image: url(../img/navbar-link_arrow.png);
    background-repeat: no-repeat;
    background-position: right center;
    padding-left: 1em;
    padding-right: 2em; }
  @media (min-width: 995px) {
    .linkFx-blue-green-season {
      font-size: 0.8em; } }
  @media (min-width: 1150px) {
    .linkFx-blue-green-season {
      font-size: 0.9em; } }

.linkFx-blue-green-season.active {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  .linkFx-blue-green-season.active span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  .linkFx-blue-green-season.active span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  .linkFx-blue-green-season.active:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  .linkFx-blue-green-season.active:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

/*=> icons */
.btn-icon_howto-bus {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  overflow: hidden;
  position: relative; }
  .btn-icon_howto-bus span.dw {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/icon-howto-bus_mixin.png");
    background-position: -72px -8px;
    background-color: transparent;
    background-repeat: no-repeat; }
  .btn-icon_howto-bus span.up {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-image: url("../img/icon-howto-bus_mixin.png");
    background-position: -72px -72px;
    background-color: transparent;
    background-repeat: no-repeat; }
  .btn-icon_howto-bus:hover span.up {
    display: block; }
  @media (min-width: 768px) {
    .btn-icon_howto-bus {
      width: 32px;
      height: 32px; }
      .btn-icon_howto-bus span.dw {
        background-position: -16px -16px; }
      .btn-icon_howto-bus span.up {
        background-position: -16px -80px; } }

.buticon.buticon-pointer-map {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 48px;
  height: 48px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  overflow: hidden;
  position: relative; }
  .buticon.buticon-pointer-map span.dw {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/icon-pointermap_mixin.png");
    background-position: -72px -8px;
    background-color: transparent;
    background-repeat: no-repeat; }
  .buticon.buticon-pointer-map span.up {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    background-image: url("../img/icon-pointermap_mixin.png");
    background-position: -72px -72px;
    background-color: transparent;
    background-repeat: no-repeat; }
  .buticon.buticon-pointer-map:hover span.up {
    display: block; }
  @media (min-width: 768px) {
    .buticon.buticon-pointer-map {
      width: 32px;
      height: 32px; }
      .buticon.buticon-pointer-map span.dw {
        background-position: -16px -16px; }
      .buticon.buticon-pointer-map span.up {
        background-position: -16px -80px; } }

/*=> image */
.buticon.buticon-video-player {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 148px;
  height: 148px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  overflow: hidden;
  position: relative; }
  .buticon.buticon-video-player span.bg.dw {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../img/innovation-video_icon.png");
    background-position: 0px 0px;
    background-color: transparent;
    background-repeat: no-repeat;
    opacity: 1;
    transition: opacity 0.3s ease-in 0s; }
  .buticon.buticon-video-player span.bg.up {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("../img/innovation-video_icon.png");
    background-position: 0px -148px;
    background-color: transparent;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.5s ease-out; }
  .buticon.buticon-video-player span.tx {
    display: none; }
  .buticon.buticon-video-player:hover span.bg.dw {
    opacity: 0;
    transition: opacity 0.5s ease-out; }
  .buticon.buticon-video-player:hover span.bg.up {
    opacity: 1;
    transition: opacity 0.3s ease-in 0s; }

.buticon.buticon-innovation-0 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 38px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-0 span.up {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 38px;
    opacity: 0;
    position: absolute;
    width: auto;
    z-index: 2;
    transition: opacity 0.3s ease-out; }
    .buticon.buticon-innovation-0 span.up .ico {
      display: none;
      float: left;
      width: 38px;
      height: 38px;
      margin-right: 12.6666666667px;
      background-image: url("../img/innovation-slides_diseno_ico_sm.png");
      background-position: 0px 0px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-0 span.up .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-0 span.up .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 38px;
      color: #fee600;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-0 span.up .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-0 span.up .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-0 span.dw {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 38px;
    opacity: 1;
    position: relative;
    width: auto;
    z-index: 1;
    transition: opacity 0.5s ease-in 0s; }
    .buticon.buticon-innovation-0 span.dw .ico {
      display: none;
      float: left;
      width: 38px;
      height: 38px;
      margin-right: 12.6666666667px;
      background-image: url("../img/innovation-slides_diseno_ico_sm.png");
      background-position: 0px -38px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-0 span.dw .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-0 span.dw .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 38px;
      color: #FFFFFF;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-0 span.dw .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-0 span.dw .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-0:hover span.up {
    opacity: 1;
    transition: opacity 0.5s ease-in 0s; }
  .buticon.buticon-innovation-0:hover span.dw {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-0.active {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 38px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-0.active span.up {
    opacity: 1;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-1 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 40px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-1 span.up {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 40px;
    opacity: 0;
    position: absolute;
    width: auto;
    z-index: 2;
    transition: opacity 0.3s ease-out; }
    .buticon.buticon-innovation-1 span.up .ico {
      display: none;
      float: left;
      width: 52px;
      height: 40px;
      margin-right: 17.3333333333px;
      background-image: url("../img/innovation-slides_comodidad_ico_sm.png");
      background-position: 0px 0px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-1 span.up .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-1 span.up .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 40px;
      color: #fee600;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-1 span.up .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-1 span.up .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-1 span.dw {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 40px;
    opacity: 1;
    position: relative;
    width: auto;
    z-index: 1;
    transition: opacity 0.5s ease-in 0s; }
    .buticon.buticon-innovation-1 span.dw .ico {
      display: none;
      float: left;
      width: 52px;
      height: 40px;
      margin-right: 17.3333333333px;
      background-image: url("../img/innovation-slides_comodidad_ico_sm.png");
      background-position: 0px -40px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-1 span.dw .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-1 span.dw .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 40px;
      color: #FFFFFF;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-1 span.dw .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-1 span.dw .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-1:hover span.up {
    opacity: 1;
    transition: opacity 0.5s ease-in 0s; }
  .buticon.buticon-innovation-1:hover span.dw {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-1.active {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 40px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-1.active span.up {
    opacity: 1;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-2 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 44px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-2 span.up {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 44px;
    opacity: 0;
    position: absolute;
    width: auto;
    z-index: 2;
    transition: opacity 0.3s ease-out; }
    .buticon.buticon-innovation-2 span.up .ico {
      display: none;
      float: left;
      width: 44px;
      height: 44px;
      margin-right: 14.6666666667px;
      background-image: url("../img/innovation-slides_equipamento_ico_sm.png");
      background-position: 0px 0px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-2 span.up .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-2 span.up .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 44px;
      color: #fee600;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-2 span.up .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-2 span.up .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-2 span.dw {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 44px;
    opacity: 1;
    position: relative;
    width: auto;
    z-index: 1;
    transition: opacity 0.5s ease-in 0s; }
    .buticon.buticon-innovation-2 span.dw .ico {
      display: none;
      float: left;
      width: 44px;
      height: 44px;
      margin-right: 14.6666666667px;
      background-image: url("../img/innovation-slides_equipamento_ico_sm.png");
      background-position: 0px -44px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-2 span.dw .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-2 span.dw .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 44px;
      color: #FFFFFF;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-2 span.dw .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-2 span.dw .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-2:hover span.up {
    opacity: 1;
    transition: opacity 0.5s ease-in 0s; }
  .buticon.buticon-innovation-2:hover span.dw {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-2.active {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 44px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-2.active span.up {
    opacity: 1;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-3 {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 55px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-3 span.up {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 55px;
    opacity: 0;
    position: absolute;
    width: auto;
    z-index: 2;
    transition: opacity 0.3s ease-out; }
    .buticon.buticon-innovation-3 span.up .ico {
      display: none;
      float: left;
      width: 31px;
      height: 55px;
      margin-right: 10.3333333333px;
      background-image: url("../img/innovation-slides_1eraclase_ico_sm.png");
      background-position: 0px 0px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-3 span.up .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-3 span.up .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 55px;
      color: #fee600;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-3 span.up .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-3 span.up .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-3 span.dw {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    height: 55px;
    opacity: 1;
    position: relative;
    width: auto;
    z-index: 1;
    transition: opacity 0.5s ease-in 0s; }
    .buticon.buticon-innovation-3 span.dw .ico {
      display: none;
      float: left;
      width: 31px;
      height: 55px;
      margin-right: 10.3333333333px;
      background-image: url("../img/innovation-slides_1eraclase_ico_sm.png");
      background-position: 0px -55px;
      background-color: transparent;
      background-repeat: no-repeat; }
      @media (min-width: 768px) {
        .buticon.buticon-innovation-3 span.dw .ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
    .buticon.buticon-innovation-3 span.dw .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: auto;
      line-height: 55px;
      color: #FFFFFF;
      font-size: 1.6em;
      font-weight: 100; }
      .buticon.buticon-innovation-3 span.dw .txt:before {
        content: "\00b7\0020"; }
        @media (min-width: 768px) {
          .buticon.buticon-innovation-3 span.dw .txt:before {
            content: ""; } }
  .buticon.buticon-innovation-3:hover span.up {
    opacity: 1;
    transition: opacity 0.5s ease-in 0s; }
  .buticon.buticon-innovation-3:hover span.dw {
    opacity: 0;
    transition: opacity 0.3s ease-out; }

.buticon.buticon-innovation-3.active {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  height: 55px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  position: relative; }
  .buticon.buticon-innovation-3.active span.up {
    opacity: 1;
    transition: opacity 0.3s ease-out; }

/*!* == @Header ===== ================= */
/*===================================== */
body.horarios #header .navbar a.linkFx_t1.link-nav-1 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.horarios #header .navbar a.linkFx_t1.link-nav-1 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.horarios #header .navbar a.linkFx_t1.link-nav-1 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.horarios #header .navbar a.linkFx_t1.link-nav-1:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.horarios #header .navbar a.linkFx_t1.link-nav-1:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

body.tarifas #header .navbar a.linkFx_t1.link-nav-2 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.tarifas #header .navbar a.linkFx_t1.link-nav-2 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.tarifas #header .navbar a.linkFx_t1.link-nav-2 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.tarifas #header .navbar a.linkFx_t1.link-nav-2:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.tarifas #header .navbar a.linkFx_t1.link-nav-2:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

body.aboutus #header .navbar a.linkFx_t1.link-nav-3 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.aboutus #header .navbar a.linkFx_t1.link-nav-3 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.aboutus #header .navbar a.linkFx_t1.link-nav-3 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.aboutus #header .navbar a.linkFx_t1.link-nav-3:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.aboutus #header .navbar a.linkFx_t1.link-nav-3:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

body.reservar #header .navbar a.linkFx_t1.link-nav-4 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.reservar #header .navbar a.linkFx_t1.link-nav-4 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.reservar #header .navbar a.linkFx_t1.link-nav-4 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.reservar #header .navbar a.linkFx_t1.link-nav-4:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.reservar #header .navbar a.linkFx_t1.link-nav-4:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

body.flota #header .navbar a.linkFx_t1.link-nav-5 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.flota #header .navbar a.linkFx_t1.link-nav-5 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.flota #header .navbar a.linkFx_t1.link-nav-5 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.flota #header .navbar a.linkFx_t1.link-nav-5:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.flota #header .navbar a.linkFx_t1.link-nav-5:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

body.contacto #header .navbar a.linkFx_t1.link-nav-6 {
  padding: 0;
  position: relative;
  text-align: center;
  overflow: hidden;
  background-color: #17286d; }
  body.contacto #header .navbar a.linkFx_t1.link-nav-6 span.dw {
    opacity: 1;
    bottom: 0;
    transition: opacity 0s ease-in; }
  body.contacto #header .navbar a.linkFx_t1.link-nav-6 span.up {
    opacity: 0;
    top: 0px;
    animation: kf_navbg_active 0s ease-out; }
  body.contacto #header .navbar a.linkFx_t1.link-nav-6:hover span.dw {
    opacity: 1;
    animation: kf_navbg_active 0s ease-out; }
  body.contacto #header .navbar a.linkFx_t1.link-nav-6:hover span.up {
    opacity: 0;
    animation: kf_navbg_active 0s ease-out; }

html.lang-es #header .nav_right li.li-lang a span {
  background-image: url(../img/icon-lang_480x32_eu.png); }

#header {
  z-index: 998;
  position: relative;
  display: block; }
  #header li a {
    font-size: 0.9em;
    width: 100%;
    text-align: center; }
  #header .nav_left {
    float: none;
    width: 100%; }
    #header .nav_left .itemli3 span {
      display: inline-block; }
      #header .nav_left .itemli3 span span {
        padding-right: 10em !important; }
    #header .nav_left li {
      position: relative; }
      #header .nav_left li .image-button {
        display: inline-block;
        width: 5em !important;
        position: absolute;
        z-index: 10;
        top: .5em;
        right: 8em; }
        #header .nav_left li .image-button img {
          width: 100%;
          display: block; }
      #header .nav_left li a.btn-home {
        width: 100%;
        height: 118px;
        background: url(../img/logo_ultramar_navbar.svg) center center no-repeat transparent; }
        #header .nav_left li a.btn-home span {
          display: none; }
    #header .nav_left li.li1 {
      background-color: #fee600; }
      #header .nav_left li.li1:hover {
        background-color: #283a83; }
      #header .nav_left li.li1 a {
        font-weight: bold; }
        #header .nav_left li.li1 a span.up span {
          background-image: url(../img/navbar-link_arrow_blue.png);
          background-repeat: no-repeat;
          background-position: right center;
          padding-right: 1em; }
        #header .nav_left li.li1 a span.dw span {
          background-image: url(../img/navbar-link_arrow.png);
          background-repeat: no-repeat;
          background-position: right center;
          padding-left: 1em;
          padding-right: 2em; }
      #header .nav_left li.li1 a.linkFx_t1 {
        padding: 0;
        position: relative;
        text-align: center;
        overflow: hidden;
        background-color: #fee600; }
        #header .nav_left li.li1 a.linkFx_t1 span.bg {
          width: 0%;
          height: 100%;
          position: absolute;
          display: block;
          z-index: 1;
          top: 0;
          left: 50%;
          opacity: 0;
          background-color: #283a83;
          animation: kf_navbg 0.5s ease-in; }
        #header .nav_left li.li1 a.linkFx_t1 span.dw {
          color: #ffffff;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
          display: block;
          width: 100%;
          position: relative;
          z-index: 2;
          opacity: 0;
          bottom: 0px;
          transition: opacity 0.3s ease-in; }
        #header .nav_left li.li1 a.linkFx_t1 span.up {
          color: #17286d;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
          display: block;
          width: 100%;
          position: absolute;
          z-index: 3;
          opacity: 1;
          top: 0px;
          animation: kf_navUp 0.3s ease-in; }
        #header .nav_left li.li1 a.linkFx_t1:hover span.bg {
          opacity: 1;
          left: 0%;
          width: 100%;
          animation: kf_navbg_over 0.5s ease-out; }
        #header .nav_left li.li1 a.linkFx_t1:hover span.dw {
          opacity: 1;
          bottom: 0;
          animation: kf_navDw_over 0.3s ease-in; }
        #header .nav_left li.li1 a.linkFx_t1:hover span.up {
          opacity: 0;
          top: 0px;
          animation: kf_navUp_hover 0.3s ease-out; }
      #header .nav_left li.li1 a.linkFx_t1.active {
        padding: 0;
        position: relative;
        text-align: center;
        overflow: hidden;
        background-color: #283a83; }
        #header .nav_left li.li1 a.linkFx_t1.active span.dw {
          opacity: 1;
          bottom: 0;
          transition: opacity 0s ease-in; }
        #header .nav_left li.li1 a.linkFx_t1.active span.up {
          opacity: 0;
          top: 0px;
          animation: kf_navbg_active 0s ease-out; }
        #header .nav_left li.li1 a.linkFx_t1.active:hover span.dw {
          opacity: 1;
          animation: kf_navbg_active 0s ease-out; }
        #header .nav_left li.li1 a.linkFx_t1.active:hover span.up {
          opacity: 0;
          animation: kf_navbg_active 0s ease-out; }
    #header .nav_left li.li2 {
      background-color: #fee600; }
      #header .nav_left li.li2:hover {
        background-color: #283a83; }
      #header .nav_left li.li2 a {
        font-weight: bold; }
        #header .nav_left li.li2 a span.up span {
          background-image: url(../img/navbar-link_arrow.png);
          background-repeat: no-repeat;
          background-position: right center;
          padding-right: 1em; }
        #header .nav_left li.li2 a span.dw span {
          background-image: url(../img/navbar-link_arrow_blue.png);
          background-repeat: no-repeat;
          background-position: right center;
          padding-left: 1em;
          padding-right: 2em; }
      #header .nav_left li.li2 a.linkFx_t1 {
        padding: 0;
        position: relative;
        text-align: center;
        overflow: hidden;
        background-color: #283a83; }
        #header .nav_left li.li2 a.linkFx_t1 span.bg {
          width: 0%;
          height: 100%;
          position: absolute;
          display: block;
          z-index: 1;
          top: 0;
          left: 50%;
          opacity: 0;
          background-color: #fee600;
          animation: kf_navbg 0.5s ease-in; }
        #header .nav_left li.li2 a.linkFx_t1 span.dw {
          color: #17286d;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
          display: block;
          width: 100%;
          position: relative;
          z-index: 2;
          opacity: 0;
          bottom: 0px;
          transition: opacity 0.3s ease-in; }
        #header .nav_left li.li2 a.linkFx_t1 span.up {
          color: #ffffff;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
          display: block;
          width: 100%;
          position: absolute;
          z-index: 3;
          opacity: 1;
          top: 0px;
          animation: kf_navUp 0.3s ease-in; }
        #header .nav_left li.li2 a.linkFx_t1:hover span.bg {
          opacity: 1;
          left: 0%;
          width: 100%;
          animation: kf_navbg_over 0.5s ease-out; }
        #header .nav_left li.li2 a.linkFx_t1:hover span.dw {
          opacity: 1;
          bottom: 0;
          animation: kf_navDw_over 0.3s ease-in; }
        #header .nav_left li.li2 a.linkFx_t1:hover span.up {
          opacity: 0;
          top: 0px;
          animation: kf_navUp_hover 0.3s ease-out; }
    #header .nav_left li.li1,
    #header .nav_left li.li2 {
      width: 50%;
      float: left;
      display: block; }
    @media (min-width: 995px) {
      #header .nav_left li.li1,
      #header .nav_left li.li2 {
        width: 100%;
        float: none; }
      #header .nav_left li.li2 {
        display: none; } }
    @media (max-width: 995px) {
      #header .nav_left .itemli3 span {
        display: inline-block; }
        #header .nav_left .itemli3 span span {
          padding-right: 1em !important; }
      #header .nav_left .itemli3 img {
        display: none !important; } }
    @media (max-width: 1154px) {
      #header .nav_left li.li1,
      #header .nav_left li.li2 {
        width: 33.33% !important;
        display: inline-block !important;
        float: none; } }
    @media (max-width: 620px) {
      #header .nav_left li.li1,
      #header .nav_left li.li2 {
        width: 100% !important; } }
    #header .nav_left .bar.ssnprm li.li1,
    #header .nav_left .bar.ssnprm li.li2 {
      width: 50%; }
    #header .nav_left .bar.ssnprm li.li2 {
      margin-left: 0%; }
    @media (min-width: 768px) {
      #header .nav_left .bar.ssnprm li.li1,
      #header .nav_left .bar.ssnprm li.li2 {
        width: 32%; }
      #header .nav_left .bar.ssnprm li.li2 {
        margin-left: 36%; } }
    @media (min-width: 995px) {
      #header .nav_left .bar.ssnprm li.li1,
      #header .nav_left .bar.ssnprm li.li2 {
        width: 100%; }
      #header .nav_left .bar.ssnprm li.li2 {
        margin-left: 0%; } }
    #header .nav_left .season-promo {
      position: absolute;
      top: 156px;
      left: 0px;
      width: 100%; }
      #header .nav_left .season-promo .ssnprm-box {
        position: relative; }
      @media (min-width: 768px) {
        #header .nav_left .season-promo {
          top: 118px;
          position: absolute;
          width: 36%;
          left: 32%; } }
      @media (min-width: 995px) {
        #header .nav_left .season-promo {
          left: 3px;
          top: 156px;
          width: 22%; } }
  #header .nav_right {
    margin: 0;
    width: 100%;
    float: none;
    display: none; }
    #header .nav_right .nav_up .bar,
    #header .nav_right .navbar .bar {
      display: none; }
  #header .nav_right li.li-lang {
    height: 54px;
    padding: 0em 2em; }
    #header .nav_right li.li-lang a {
      color: #596aaf;
      height: 32px;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      top: 50%;
      margin-top: -26px;
      display: inline-block;
      *display: inline;
      *zoom: 1; }
      #header .nav_right li.li-lang a span {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        opacity: 1;
        width: 32px;
        height: 32px;
        vertical-align: middle;
        background-image: url(../img/icon-lang_480x32.png);
        background-repeat: no-repeat;
        background-position: left center;
        margin-right: 0.35em; }
      #header .nav_right li.li-lang a:hover {
        color: rgba(255, 255, 255, 0.5); }
        #header .nav_right li.li-lang a:hover span {
          opacity: 0.5;
          background-position: right center; }
  #header .nav_right li.li-login {
    height: 54px;
    padding: 0em 1em 0em 2em;
    opacity: 1; }
    #header .nav_right li.li-login span.icon {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      float: left;
      opacity: 1;
      width: 32px;
      height: 32px;
      top: 50%;
      margin-top: -16px;
      vertical-align: middle;
      background-image: url(../img/icon-session_480x32.png);
      background-repeat: no-repeat;
      background-position: left center;
      font-size: 0.9em; }
    #header .nav_right li.li-login:hover span.icon {
      opacity: 0.5;
      background-position: right center; }
    #header .nav_right li.li-login i.cog-icon,
    #header .nav_right li.li-login i.user-icon,
    #header .nav_right li.li-login a.register,
    #header .nav_right li.li-login a.login,
    #header .nav_right li.li-login a.user {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      float: left;
      color: #596aaf;
      height: 32px;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
      top: 50%;
      margin: 0;
      padding: 0em 0.35em 0em 0em;
      position: relative;
      margin-top: -16px;
      width: auto; }
      #header .nav_right li.li-login i.cog-icon:hover,
      #header .nav_right li.li-login i.user-icon:hover,
      #header .nav_right li.li-login a.register:hover,
      #header .nav_right li.li-login a.login:hover,
      #header .nav_right li.li-login a.user:hover {
        color: rgba(255, 255, 255, 0.5); }
  @media (min-width: 768px) {
    #header .nav_left {
      float: none;
      width: 100%; }
    #header .nav_right {
      display: block;
      width: 100%;
      float: none; }
      #header .nav_right .nav_down .bar {
        display: block; } }
  @media (min-width: 1155px) {
    #header .nav_left {
      float: left;
      width: 22%; }
    #header .nav_right {
      display: block;
      width: 78%;
      float: left; }
      #header .nav_right .nav_up .bar,
      #header .nav_right .navbar .bar {
        display: block; } }
  @media (max-width: 1155px) {
    #header .nav_left .bar {
      font-size: 0px !important; }
      #header .nav_left .bar li {
        font-size: 16px; } }
  #header .nav_up, #header .navbar, #header .nav_down {
    display: block;
    width: 100%; }
    #header .nav_up li, #header .navbar li, #header .nav_down li {
      float: left; }
      #header .nav_up li a, #header .navbar li a, #header .nav_down li a {
        color: #fff; }
  #header .nav_up {
    background-color: #17286d; }
    #header .nav_up ul {
      height: 54px; }
      #header .nav_up ul li {
        padding: 0em 0em; }
        #header .nav_up ul li a {
          color: #596aaf; }
      #header .nav_up ul li.li-social {
        padding: 0; }
        #header .nav_up ul li.li-social ul.social {
          padding: 0 1em; }
          #header .nav_up ul li.li-social ul.social li {
            float: left;
            padding: 0 .35em;
            position: relative;
            top: 50%;
            margin-top: -16px; }
            #header .nav_up ul li.li-social ul.social li a.ta {
              background-image: url(../img/icon-tripadvisor_32x32.png); }
            #header .nav_up ul li.li-social ul.social li a.fb {
              background-image: url(../img/icon-facebook_32x32.png); }
            #header .nav_up ul li.li-social ul.social li a.tw {
              background-image: url(../img/icon-twitter_32x32.png); }
            #header .nav_up ul li.li-social ul.social li a.it {
              background-image: url(../img/icon-instagram_32x32.png); }
            #header .nav_up ul li.li-social ul.social li a {
              opacity: .5;
              width: 32px;
              height: 32px;
              background-repeat: no-repeat;
              background-position: center -32px; }
              #header .nav_up ul li.li-social ul.social li a span {
                display: none; }
              #header .nav_up ul li.li-social ul.social li a:hover {
                background-position: center 0px; }
      #header .nav_up ul li.li-facturar {
        float: right;
        margin-right: 1.5em; }
        #header .nav_up ul li.li-facturar #btn-billing {
          vertical-align: middle;
          position: relative;
          overflow: hidden;
          z-index: 1;
          padding-top: 0;
          padding-bottom: 0;
          background-color: #fee600; }
          #header .nav_up ul li.li-facturar #btn-billing span {
            display: block;
            position: absolute; }
          #header .nav_up ul li.li-facturar #btn-billing span.bg {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0; }
          #header .nav_up ul li.li-facturar #btn-billing span.tx.up {
            z-index: 4; }
          #header .nav_up ul li.li-facturar #btn-billing span.tx.dw {
            position: relative;
            z-index: 5; }
          #header .nav_up ul li.li-facturar #btn-billing span.bg.up {
            opacity: 1;
            z-index: 2; }
          #header .nav_up ul li.li-facturar #btn-billing span.bg.dw {
            z-index: 3; }
          #header .nav_up ul li.li-facturar #btn-billing span.tx.up {
            color: #17286d;
            opacity: 0;
            transition: opacity 0.5s ease-out;
            margin-left: 3px;
            animation: kf_button_push_3px 0.35s ease-out; }
          #header .nav_up ul li.li-facturar #btn-billing span.tx.dw {
            opacity: 1;
            color: #17286d;
            transition: opacity 0.5s ease-in 0.5s; }
          #header .nav_up ul li.li-facturar #btn-billing span.bg.up {
            opacity: 0;
            background-color: #FFFFFF;
            transition: opacity 0.5s ease-out; }
          #header .nav_up ul li.li-facturar #btn-billing span.bg.dw {
            opacity: 1;
            background-color: #fee600;
            transition: opacity 0s ease-in 0.5s; }
          #header .nav_up ul li.li-facturar #btn-billing:hover {
            background-color: #FFFFFF; }
            #header .nav_up ul li.li-facturar #btn-billing:hover span.tx.up {
              opacity: 1;
              transition: opacity 0.5s ease-in 0.5s;
              margin-left: 0px;
              animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
            #header .nav_up ul li.li-facturar #btn-billing:hover span.tx.dw {
              opacity: 0;
              transition: opacity 0.5s ease-out; }
            #header .nav_up ul li.li-facturar #btn-billing:hover span.bg.up {
              opacity: 1;
              transition: opacity 0s ease-in 0.5s; }
            #header .nav_up ul li.li-facturar #btn-billing:hover span.bg.dw {
              opacity: 0;
              transition: opacity 0.5s ease-out; }
      #header .nav_up ul li.li-innovation {
        float: right;
        margin-right: 1.5em; }
  #header .navbar {
    background-color: #fee600; }
    #header .navbar ul li {
      width: 15.5%; }
      #header .navbar ul li a {
        background: url(../img/navbar-line_right.png) right center no-repeat; }
    #header .navbar ul li:first-child {
      width: 20%; }
    #header .navbar ul li:last-child {
      background-image: none; }
    #header .navbar a.linkFx_t1 {
      font-weight: bold;
      padding: 0;
      position: relative;
      text-align: center;
      overflow: hidden;
      background-color: #fee600; }
      #header .navbar a.linkFx_t1 span.bg {
        width: 0%;
        height: 100%;
        position: absolute;
        display: block;
        z-index: 1;
        top: 0;
        left: 50%;
        opacity: 0;
        background-color: #283a83;
        animation: kf_navbg 0.5s ease-in; }
      #header .navbar a.linkFx_t1 span.dw {
        color: #FFFFFF;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        display: block;
        width: 100%;
        position: relative;
        z-index: 2;
        opacity: 0;
        bottom: 0px;
        transition: opacity 0.3s ease-in; }
      #header .navbar a.linkFx_t1 span.up {
        color: #17286d;
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        display: block;
        width: 100%;
        position: absolute;
        z-index: 3;
        opacity: 1;
        top: 0px;
        animation: kf_navUp 0.3s ease-in; }
      #header .navbar a.linkFx_t1:hover span.bg {
        opacity: 1;
        left: 0%;
        width: 100%;
        animation: kf_navbg_over 0.5s ease-out; }
      #header .navbar a.linkFx_t1:hover span.dw {
        opacity: 1;
        bottom: 0;
        animation: kf_navDw_over 0.3s ease-in; }
      #header .navbar a.linkFx_t1:hover span.up {
        opacity: 0;
        top: 0px;
        animation: kf_navUp_hover 0.3s ease-out; }
  #header .nav_down {
    background-color: #07829b; }
    #header .nav_down ul li {
      width: 34%;
      border-right: solid 1px #07829b;
      background-color: #2290a6; }
      #header .nav_down ul li:hover {
        background-color: #07829b; }
      #header .nav_down ul li a span.up span {
        background-image: url(../img/navbar-link_arrow.png);
        background-repeat: no-repeat;
        background-position: right center;
        padding-right: 1em; }
      #header .nav_down ul li a span.dw span {
        background-image: url(../img/navbar-link_arrow.png);
        background-repeat: no-repeat;
        background-position: right center;
        padding-left: 1em;
        padding-right: 2em; }
    #header .nav_down ul li:first-child {
      width: 32%; }
    #header .nav_down ul li:last-child {
      width: 34%;
      border: 0; }
    #header .nav_down a.linkFx_t1 {
      padding: 0;
      position: relative;
      text-align: center;
      overflow: hidden;
      background-color: #2290a6; }
      #header .nav_down a.linkFx_t1 span.bg {
        width: 0%;
        height: 100%;
        position: absolute;
        display: block;
        z-index: 1;
        top: 0;
        left: 50%;
        opacity: 0;
        background-color: #07829b;
        animation: kf_navbg 0.5s ease-in; }
      #header .nav_down a.linkFx_t1 span.dw {
        color: #ffffff;
        padding-top: 0.6em;
        padding-bottom: 0.6em;
        display: block;
        width: 100%;
        position: relative;
        z-index: 2;
        opacity: 0;
        bottom: 0px;
        transition: opacity 0.3s ease-in; }
      #header .nav_down a.linkFx_t1 span.up {
        color: #ffffff;
        padding-top: 0.6em;
        padding-bottom: 0.6em;
        display: block;
        width: 100%;
        position: absolute;
        z-index: 3;
        opacity: 1;
        top: 0px;
        animation: kf_navUp 0.3s ease-in; }
      #header .nav_down a.linkFx_t1:hover span.bg {
        opacity: 1;
        left: 0%;
        width: 100%;
        animation: kf_navbg_over 0.5s ease-out; }
      #header .nav_down a.linkFx_t1:hover span.dw {
        opacity: 1;
        bottom: 0;
        animation: kf_navDw_over 0.3s ease-in; }
      #header .nav_down a.linkFx_t1:hover span.up {
        opacity: 0;
        top: 0px;
        animation: kf_navUp_hover 0.3s ease-out; }
    #header .nav_down a.linkFx_t1.link-paquete span.up span {
      background-image: url(../img/navbar-link_paquetes.png);
      background-repeat: no-repeat;
      background-position: right center;
      padding-right: 5em; }
    #header .nav_down a.linkFx_t1.link-paquete span.dw span {
      background-image: url(../img/navbar-link_paquetes.png);
      background-repeat: no-repeat;
      background-position: right center;
      padding-left: 1em;
      padding-right: 6em; }

body.delete-home #header .navbar {
  background-color: #fee600; }
  body.delete-home #header .navbar a.linkFx_t1 {
    font-weight: bold;
    padding: 0;
    position: relative;
    text-align: center;
    overflow: hidden;
    background-color: #fee600; }
    body.delete-home #header .navbar a.linkFx_t1 span.bg {
      width: 0%;
      height: 100%;
      position: absolute;
      display: block;
      z-index: 1;
      top: 0;
      left: 50%;
      opacity: 0;
      background-color: #283a83;
      animation: kf_navbg 0.5s ease-in; }
    body.delete-home #header .navbar a.linkFx_t1 span.dw {
      color: #FFFFFF;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      display: block;
      width: 100%;
      position: relative;
      z-index: 2;
      opacity: 0;
      bottom: 0px;
      transition: opacity 0.3s ease-in; }
    body.delete-home #header .navbar a.linkFx_t1 span.up {
      color: #17286d;
      padding-top: 1.5em;
      padding-bottom: 1.5em;
      display: block;
      width: 100%;
      position: absolute;
      z-index: 3;
      opacity: 1;
      top: 0px;
      animation: kf_navUp 0.3s ease-in; }
    body.delete-home #header .navbar a.linkFx_t1:hover span.bg {
      opacity: 1;
      left: 0%;
      width: 100%;
      animation: kf_navbg_over 0.5s ease-out; }
    body.delete-home #header .navbar a.linkFx_t1:hover span.dw {
      opacity: 1;
      bottom: 0;
      animation: kf_navDw_over 0.3s ease-in; }
    body.delete-home #header .navbar a.linkFx_t1:hover span.up {
      opacity: 0;
      top: 0px;
      animation: kf_navUp_hover 0.3s ease-out; }

body.delete-home #header .nav_right li.li-lang a {
  color: #17286d;
  font-weight: bold; }
  body.delete-home #header .nav_right li.li-lang a span {
    background-image: url(../img/icon-lang_480x32_blue-to-green.png); }
  body.delete-home #header .nav_right li.li-lang a:hover {
    color: #08829a; }
    body.delete-home #header .nav_right li.li-lang a:hover span {
      opacity: 1; }

body.delete-home #header .nav_up {
  background-color: #fee600; }
  body.delete-home #header .nav_up ul li.li-social ul.social li a.ta {
    background-image: url(../img/icon-tripadvisor_32x32_blue-to-green.png); }
  body.delete-home #header .nav_up ul li.li-social ul.social li a.fb {
    background-image: url(../img/icon-facebook_32x32_blue-to-green.png); }
  body.delete-home #header .nav_up ul li.li-social ul.social li a.tw {
    background-image: url(../img/icon-twitter_32x32_blue-to-green.png); }
  body.delete-home #header .nav_up ul li.li-social ul.social li a.it {
    background-image: url(../img/icon-instagram_32x32_blue-to-green.png); }
  body.delete-home #header .nav_up ul li.li-social ul.social li a {
    opacity: 1; }
  body.delete-home #header .nav_up ul li.li-facturar #btn-billing {
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    z-index: 1;
    padding-top: 0;
    padding-bottom: 0;
    background-color: #17286d; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span {
      display: block;
      position: absolute; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.bg {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.tx.up {
      z-index: 4; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.tx.dw {
      position: relative;
      z-index: 5; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.bg.up {
      opacity: 1;
      z-index: 2; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.bg.dw {
      z-index: 3; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.tx.up {
      color: #FFFFFF;
      opacity: 0;
      transition: opacity 0.5s ease-out;
      margin-left: 3px;
      animation: kf_button_push_3px 0.35s ease-out; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.tx.dw {
      opacity: 1;
      color: #FFFFFF;
      transition: opacity 0.5s ease-in 0.5s; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.bg.up {
      opacity: 0;
      background-color: #08829a;
      transition: opacity 0.5s ease-out; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing span.bg.dw {
      opacity: 1;
      background-color: #17286d;
      transition: opacity 0s ease-in 0.5s; }
    body.delete-home #header .nav_up ul li.li-facturar #btn-billing:hover {
      background-color: #08829a; }
      body.delete-home #header .nav_up ul li.li-facturar #btn-billing:hover span.tx.up {
        opacity: 1;
        transition: opacity 0.5s ease-in 0.5s;
        margin-left: 0px;
        animation: kf_button_pull_3px 0.35s ease-in 0.5s; }
      body.delete-home #header .nav_up ul li.li-facturar #btn-billing:hover span.tx.dw {
        opacity: 0;
        transition: opacity 0.5s ease-out; }
      body.delete-home #header .nav_up ul li.li-facturar #btn-billing:hover span.bg.up {
        opacity: 1;
        transition: opacity 0s ease-in 0.5s; }
      body.delete-home #header .nav_up ul li.li-facturar #btn-billing:hover span.bg.dw {
        opacity: 0;
        transition: opacity 0.5s ease-out; }

.cover-size {
  width: 100%;
  height: auto;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: fixed;
  background-size: cover; }

.box-center-height {
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%; }

.divider {
  display: block;
  border-bottom: 2px solid #dcdfeb;
  height: 2px;
  width: 100%;
  padding-top: 1em;
  margin-bottom: 1.5em; }
  @media (min-width: 768px) {
    .divider {
      padding-top: 3em;
      margin-bottom: 4em; } }

/*=> @TITLES */
body h2.title {
  color: #07829b;
  font-size: 1.35em; }

/*=> waves */
body #bd-waves.wp-body {
  background-image: url(../img/olas-body.png);
  background-color: transparent;
  background-position: center top;
  background-repeat: repeat-x;
  height: 420px;
  margin-top: -17em;
  z-index: 99;
  position: relative; }

body #bd-waves-B.wp-body {
  background-image: url(../img/olas-hero-contacto.png);
  background-color: transparent;
  background-position: center top;
  background-repeat: repeat-x;
  height: 280px;
  margin-top: 0em;
  z-index: 99;
  position: relative; }
  @media (min-width: 768px) {
    body #bd-waves-B.wp-body {
      height: 330px; } }
  @media (min-width: 995px) {
    body #bd-waves-B.wp-body {
      height: 420px; } }

/*=> @PROMOCIONES */
body #prmtn-gnrl.wp-body {
  padding-bottom: 6em; }
  @media (min-width: 768px) {
    body #prmtn-gnrl.wp-body {
      padding-bottom: 0em; } }

body #prmtn-gnrl {
  background-color: #283a83;
  background-image: url(../img/prmtn_reserva_ft.jpg);
  background-position: center top;
  background-repeat: no-repeat;
  margin-top: 3em; }
  body #prmtn-gnrl .prmtn-box {
    text-align: left;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto; }
    body #prmtn-gnrl .prmtn-box .prmtn-cont {
      padding-left: 0;
      padding-top: 1em; }
      body #prmtn-gnrl .prmtn-box .prmtn-cont .title {
        text-align: center;
        color: #fee600;
        font-size: 2em;
        line-height: 100%; }
        body #prmtn-gnrl .prmtn-box .prmtn-cont .title span {
          font-size: 1em; }
      @media (min-width: 768px) {
        body #prmtn-gnrl .prmtn-box .prmtn-cont .title {
          text-align: left;
          font-size: 2.5em; } }
      @media (min-width: 995px) {
        body #prmtn-gnrl .prmtn-box .prmtn-cont .title {
          font-size: 3.35em; } }
      @media (min-width: 1200px) {
        body #prmtn-gnrl .prmtn-box .prmtn-cont .title {
          font-size: 3.5em; } }
    @media (min-width: 768px) {
      body #prmtn-gnrl .prmtn-box .prmtn-cont {
        padding-bottom: 8em; } }
  @media (min-width: 768px) {
    body #prmtn-gnrl {
      background-position: 12% top;
      margin-top: 6em; } }
  @media (min-width: 995px) {
    body #prmtn-gnrl {
      background-position: 5% top; } }
  @media (min-width: 1200px) {
    body #prmtn-gnrl {
      background-position: 3% top; }
      body #prmtn-gnrl #prmtn-cont {
        height: 270px; } }
  @media (min-width: 1320px) {
    body #prmtn-gnrl {
      background-position: 0% top; } }

#main {
  z-index: 1;
  position: relative; }

/*=> BREADCRUMB
 *=======================================*/
ul.breadcrumb {
  padding: 0px 0px;
  list-style: none;
  background-color: transparent;
  margin-bottom: 0em; }
  ul.breadcrumb li {
    display: inline;
    color: white;
    font-size: 0.9em; }
  ul.breadcrumb li + li:before {
    padding: 0px 4px;
    color: white;
    content: "/\00a0"; }
  ul.breadcrumb li a {
    color: white;
    font-size: 0.9em; }
    ul.breadcrumb li a:hover {
      color: #fee600; }

.breadcrumb-inverse ul.breadcrumb li {
  color: #54596f; }

.breadcrumb-inverse ul.breadcrumb li + li:before {
  color: #54596f; }

.breadcrumb-inverse ul.breadcrumb li a {
  color: #54596f; }
  .breadcrumb-inverse ul.breadcrumb li a:hover {
    color: #17286d; }

/*=> RESERVAS PRICERES
 *=======================================*/
.pt-customJqueryUi {
  position: absolute;
  z-index: 1001; }

/*=> MODAL
 *=======================================*/
body .modal-rsrv.close {
  display: none; }

body .modal-rsrv.open {
  display: block; }

body .modal-rsrv {
  display: none;
  position: fixed;
  top: 10%;
  left: 0%;
  width: 96%;
  height: 0;
  z-index: 998; }
  body .modal-rsrv .modal-box {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    margin: -80px 0 0 0; }
    body .modal-rsrv .modal-box a.modal-close {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      right: 0px;
      top: 0;
      background-image: url(../img/modal-icon_close.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #17286d;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0px 0px 6px 1px white; }
      body .modal-rsrv .modal-box a.modal-close span {
        display: none; }
      body .modal-rsrv .modal-box a.modal-close:hover {
        background-color: #3b4e9a; }
    @media (min-width: 768px) {
      body .modal-rsrv .modal-box a.modal-close {
        position: absolute;
        right: -50px; } }
    body .modal-rsrv .modal-box .modal-main {
      width: 100%;
      height: 100%;
      background-color: #47a9bd;
      border-radius: 0.75em;
      overflow: hidden;
      box-shadow: 0px 0px 6px 1px white; }

@media (min-width: 768px) {
  body .modal-rsrv {
    width: 0;
    top: 20%;
    left: 50%; }
    body .modal-rsrv .modal-box {
      width: 390px;
      margin: -80px 0 0 -195px; } }

body .modal-schdl.close {
  display: none; }

body .modal-schdl.open {
  display: block; }
  body .modal-schdl.open .modal-box {
    left: 0px;
    opacity: 1;
    left: 0px;
    transition: opacity 0.5s ease-in;
    animation: kf_modalFx_push 0.5s ease-in; }

body .modal-schdl {
  display: none;
  position: absolute;
  top: 12.5em;
  left: 1em;
  width: 90%;
  height: 0;
  z-index: 999; }
  body .modal-schdl .modal-box {
    width: 100%;
    height: auto;
    position: relative;
    left: -16px; }
    body .modal-schdl .modal-box a.modal-close {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      right: 0px;
      top: 0;
      background-image: url(../img/modal-icon_close.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #07829b;
      width: 40px;
      height: 40px;
      border-radius: 50%; }
      body .modal-schdl .modal-box a.modal-close span {
        display: none; }
      body .modal-schdl .modal-box a.modal-close:hover {
        background-color: #47a9bd; }
    @media (min-width: 768px) {
      body .modal-schdl .modal-box a.modal-close {
        position: absolute;
        right: -50px; } }
    body .modal-schdl .modal-box .modal-main {
      width: 100%;
      height: 100%;
      background-color: #47a9bd;
      border-radius: 0.75em;
      overflow: hidden; }
  body .modal-schdl ul {
    display: block;
    padding: 0;
    margin: 0;
    line-height: 100%;
    list-style-type: none;
    color: #fff;
    width: 100%; }
  body .modal-schdl .ul-root .li-root .title-root {
    display: block;
    width: 100%;
    background-color: #07829b;
    padding: 0.9em 1em 0.9em 1em;
    font-size: 1.1em; }
  body .modal-schdl .ul-root .ul-node .li-node a.link-node {
    border-bottom: 1px solid #07829b;
    display: block;
    background-color: #2290a6;
    width: 100%;
    color: #fff;
    font-weight: lighter;
    font-size: 1em;
    padding: 0.7em 0em 0.7em 1em;
    text-align: left; }
    body .modal-schdl .ul-root .ul-node .li-node a.link-node .btn-arrow {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      float: right;
      width: 32px;
      margin-right: 0.35em;
      background-image: url(../img/icon-arrow_down_sm.png);
      background-position: left center;
      background-repeat: no-repeat; }
    body .modal-schdl .ul-root .ul-node .li-node a.link-node:hover {
      color: #fee600; }
      body .modal-schdl .ul-root .ul-node .li-node a.link-node:hover .btn-arrow {
        background-position: right center; }
  body .modal-schdl .ul-root .ul-node .li-node.close .ul-child {
    display: none; }
  body .modal-schdl .ul-root .ul-node .li-node.open .ul-child {
    display: block; }
  body .modal-schdl .ul-root .ul-node .li-node.open a.link-node {
    color: #fee600; }
    body .modal-schdl .ul-root .ul-node .li-node.open a.link-node .btn-arrow {
      background-image: url(../img/icon-arrow_up_sm.png);
      background-position: right center; }
  body .modal-schdl .ul-root .ul-child .li-child {
    background-color: #2290a6;
    float: left;
    width: 50%; }
  body .modal-schdl .ul-root .ul-child .li-child.li-child-two {
    text-align: right; }
  body .modal-schdl .ul-root .ul-schdl {
    background-color: #47a9bd;
    width: 99%;
    text-align: center; }
    body .modal-schdl .ul-root .ul-schdl li {
      padding: 0.2em 0em; }
      body .modal-schdl .ul-root .ul-schdl li span {
        padding-right: 0.35em; }
    body .modal-schdl .ul-root .ul-schdl li:nth-child(even) {
      background-color: #6cbaca; }
    body .modal-schdl .ul-root .ul-schdl li:nth-child(odd) {
      background-color: #47a9bd; }
    body .modal-schdl .ul-root .ul-schdl .icon-ship_go {
      display: block;
      width: 100%;
      height: 70px;
      margin: 0em 0em 0.8em 0em;
      background-image: url(../img/icon-ship_go_white_noarrow.png);
      background-position: center bottom;
      background-repeat: no-repeat; }
    body .modal-schdl .ul-root .ul-schdl .icon-ship_return {
      display: block;
      width: 100%;
      height: 70px;
      margin: 0em 0em 0.8em 0em;
      background-image: url(../img/icon-ship_return_white_noarrow.png);
      background-position: center bottom;
      background-repeat: no-repeat; }
  body .modal-schdl .ul-root .ul-schdl.ul-schdl-return {
    margin: 0 0 0 auto; }
  body .modal-schdl .ul-root .ul-schdl.ul-schdl-go {
    margin: 0; }

@media (min-width: 768px) {
  body .modal-schdl {
    width: 0; }
    body .modal-schdl .modal-box {
      width: 360px; } }

body .modal-ssnprm.close {
  display: none; }

body .modal-ssnprm.open {
  display: block; }
  body .modal-ssnprm.open .modal-box {
    left: 0px;
    opacity: 1;
    left: 0px;
    transition: opacity 0.5s ease-in;
    animation: kf_modalFx_push 0.5s ease-in; }

body .modal-ssnprm {
  display: none;
  position: absolute;
  top: 12.5em;
  left: 1em;
  width: 90%;
  height: 0;
  z-index: 999; }
  body .modal-ssnprm .modal-box {
    width: 100%;
    height: auto;
    position: relative;
    left: -16px; }
    body .modal-ssnprm .modal-box a.modal-close {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      right: 0px;
      top: 0;
      background-image: url(../img/modal-icon_close.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #17286d;
      width: 40px;
      height: 40px;
      border-radius: 50%; }
      body .modal-ssnprm .modal-box a.modal-close span {
        display: none; }
      body .modal-ssnprm .modal-box a.modal-close:hover {
        background-color: #3b4e9a; }
    @media (min-width: 768px) {
      body .modal-ssnprm .modal-box a.modal-close {
        position: absolute;
        right: -50px; } }

@media (min-width: 768px) {
  body .modal-ssnprm {
    width: 0; }
    body .modal-ssnprm .modal-box {
      width: 480px; } }

body .modal-rts_cpj_im.close {
  display: none; }

body .modal-rts_cpj_im.open {
  display: block; }

body .modal-rts_cpj_im {
  display: none;
  position: fixed;
  top: 50%;
  left: 0%;
  width: 96%;
  height: 0;
  z-index: 998; }
  body .modal-rts_cpj_im .modal-box {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
    left: 0;
    margin: -80px 0 0 0; }
    body .modal-rts_cpj_im .modal-box a.modal-close {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      right: 0px;
      top: 0;
      background-image: url(../img/modal-icon_close.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-color: #17286d;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0px 0px 6px 1px white; }
      body .modal-rts_cpj_im .modal-box a.modal-close span {
        display: none; }
      body .modal-rts_cpj_im .modal-box a.modal-close:hover {
        background-color: #3b4e9a; }
    @media (min-width: 768px) {
      body .modal-rts_cpj_im .modal-box a.modal-close {
        position: absolute;
        right: -50px; } }
    body .modal-rts_cpj_im .modal-box .modal-main {
      width: 100%;
      height: 100%;
      margin: 0px -5px 0px -5px;
      background-color: #17286d;
      border-radius: 0.75em;
      overflow: hidden;
      box-shadow: 0px 0px 6px 1px white; }

@media (min-width: 768px) {
  body .modal-rts_cpj_im {
    width: 0;
    top: 50%;
    left: 50%; }
    body .modal-rts_cpj_im .modal-box {
      width: 390px;
      margin: -80px 0 0 -195px; } }

body.home .modal-schdl {
  /*.ul-root*/ }
  body.home .modal-schdl .modal-box a.modal-close {
    background-color: #17286d; }
    body.home .modal-schdl .modal-box a.modal-close:hover {
      background-color: #3b4e9a; }
  body.home .modal-schdl .modal-box .modal-main {
    background-color: #3b4e9a; }
  body.home .modal-schdl .li-root .title-root {
    background-color: #17286d; }
  body.home .modal-schdl .li-root .li-node a.link-node {
    border-bottom: 1px solid #17286d;
    background-color: #283a83; }
  body.home .modal-schdl .li-root .ul-child .li-child {
    background-color: #17286d; }
  body.home .modal-schdl .li-root .ul-schdl {
    background-color: #3b4e9a; }
    body.home .modal-schdl .li-root .ul-schdl li:nth-child(even) {
      background-color: #6271ae; }
    body.home .modal-schdl .li-root .ul-schdl li:nth-child(odd) {
      background-color: #3b4e9a; }
    body.home .modal-schdl .li-root .ul-schdl li.li-hd {
      background-color: #3b4e9a; }

/*=> @vCard
 *=======================================*/
.vcard {
  margin-top: 0;
  margin-bottom: 1em; }
  .vcard abbr {
    border-bottom: 0px dotted #fff; }
  .vcard address {
    margin-bottom: 0em; }
  .vcard dd, .vcard dt {
    line-height: 120%; }
    .vcard dd ul, .vcard dt ul {
      margin: 0;
      padding: 0; }
      .vcard dd ul li, .vcard dt ul li {
        margin: 0;
        padding: 0; }
  .vcard dl {
    margin-top: 0;
    margin-bottom: 0; }

.pg-contacto {
  padding: 0;
  margin: 0;
  list-style-type: none; }
  .pg-contacto .vcard {
    margin-bottom: 2.5em; }
    .pg-contacto .vcard strong {
      font-weight: 500; }
    .pg-contacto .vcard .org {
      padding-top: 0.7em; }
    .pg-contacto .vcard a.tel {
      color: #fff;
      font-size: 1.5em;
      font-weight: lighter;
      line-height: 100%; }
      @media (min-width: 768px) {
        .pg-contacto .vcard a.tel {
          font-size: 2.3em; } }
  .pg-contacto .email.type a.email {
    color: #fff; }

/*=> @FOOTER
 *=======================================*/
#footer {
  margin-top: -80px;
  display: block;
  position: relative;
  z-index: 99;
  color: #4b5da7; }
  #footer a {
    color: #4b5da7; }
    #footer a:hover {
      color: #fff; }
  #footer .ft-waves {
    background-image: url(../img/olas-footer.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 100%;
    height: 205px; }
  #footer .ft-main {
    background-color: #17286d; }
    #footer .ft-main .box {
      margin-bottom: 1em; }
      #footer .ft-main .box:hover {
        cursor: default; }
        #footer .ft-main .box:hover .icon-box {
          color: #fff;
          opacity: 0.5; }
          #footer .ft-main .box:hover .icon-box .icon-locator,
          #footer .ft-main .box:hover .icon-box .icon-social,
          #footer .ft-main .box:hover .icon-box .icon-surf {
            background-position: right center; }
    #footer .ft-main .box.first-child {
      margin-left: 2%; }
      #footer .ft-main .box.first-child ul.list {
        padding-left: 0; }
    #footer .ft-main .box.last-child {
      margin-right: 0%;
      background-color: #fff;
      border-radius: 0.75em;
      padding: 1.3em 1.5em; }
    @media (min-width: 995px) {
      #footer .ft-main .box.first-child {
        margin-left: 5%; }
      #footer .ft-main .box.last-child {
        margin-right: 5%;
        padding: 1em 1em; } }
    @media (min-width: 1200px) {
      #footer .ft-main .box.first-child {
        margin-left: 10%; }
      #footer .ft-main .box.last-child {
        margin-right: 10%;
        padding: 1.3em 2em; } }
  #footer ul.list {
    list-style-type: none;
    padding: 0 0 0 0em;
    margin: 0 0 .75em 0;
    display: block;
    text-align: left;
    font-size: 0.9em; }
    #footer ul.list li {
      padding: 0 0 .15em 0;
      margin: 0;
      line-height: 120%; }
  @media (min-width: 768px) {
    #footer ul.list {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      padding: 0 0 0 1.45em; } }
  #footer ul.list.adress li {
    padding-bottom: 1em; }
    #footer ul.list.adress li:hover {
      color: #fff; }
  #footer ul.list.social_navbar {
    padding: 0 0 0 0.5em;
    margin-top: -0.5em; }
    #footer ul.list.social_navbar li {
      padding: 0 0 0 0; }
      #footer ul.list.social_navbar li a {
        line-height: 32px; }
      #footer ul.list.social_navbar li a.ta span.icon {
        background-image: url(../img/icon-tripadvisor_32x32.png); }
      #footer ul.list.social_navbar li a.fb span.icon {
        background-image: url(../img/icon-facebook_32x32_right.png); }
      #footer ul.list.social_navbar li a.tw span.icon {
        background-image: url(../img/icon-twitter_32x32_right.png); }
      #footer ul.list.social_navbar li a.it span.icon {
        background-image: url(../img/icon-instagram_32x32_right.png); }
      #footer ul.list.social_navbar li a span.icon {
        opacity: 1;
        width: 32px;
        height: 32px;
        margin-right: 0.15em;
        display: inline-block;
        *display: inline;
        *zoom: 1;
        background-repeat: no-repeat;
        background-position: right -32px; }
      #footer ul.list.social_navbar li a:hover span.icon {
        background-position: right 0px; }
  #footer ul.list .derechos,
  #footer ul.list .payments {
    margin-top: 0.5em;
    float: left;
    width: 100%; }
  #footer ul.list .payments .icon {
    opacity: .95;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    background-repeat: no-repeat;
    background-position: center center;
    width: auto;
    height: 24px;
    margin-top: 0em; }
    #footer ul.list .payments .icon span {
      display: none; }
  #footer ul.list .payments .icon-paypal {
    background-image: url(../img/icon-paypal_74x24.png);
    width: 74px;
    margin-right: 1em; }
  #footer ul.list .payments .icon-visa {
    background-image: url(../img/icon-visa_48x24.png);
    width: 48px;
    margin-right: 1.5em; }
  #footer ul.list .payments .icon-mastercard {
    background-image: url(../img/icon-mastercard_38x24.png);
    width: 38px; }
  @media (min-width: 768px) {
    #footer ul.list .payments,
    #footer ul.list .derechos {
      margin-top: 0em; }
      #footer ul.list .payments .icon,
      #footer ul.list .derechos .icon {
        margin-top: 1.3em; } }
  #footer .icon-box {
    padding-left: 0em;
    margin-bottom: 0.9em;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-weight: bold;
    font-size: 1.15em;
    height: 32px;
    line-height: 32px;
    width: 100%; }
    #footer .icon-box .icon-locator {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      float: left;
      width: 17px;
      height: 32px;
      margin-right: 0.25em;
      background-image: url(../img/icon-locator_footer.png);
      background-position: left center;
      background-repeat: no-repeat;
      background-color: transparent; }
      #footer .icon-box .icon-locator:hover {
        background-position: right center; }
    #footer .icon-box .icon-social {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      float: left;
      width: 24px;
      height: 32px;
      margin-right: 0.2em;
      background-image: url(../img/icon-social_footer.png);
      background-position: left center;
      background-repeat: no-repeat;
      background-color: transparent; }
      #footer .icon-box .icon-social:hover {
        background-position: right center; }
    #footer .icon-box .icon-surf {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      float: left;
      width: 14px;
      height: 32px;
      margin-right: 0.3em;
      background-image: url(../img/icon-surf_footer.png);
      background-position: left center;
      background-repeat: no-repeat;
      background-color: transparent; }
      #footer .icon-box .icon-surf:hover {
        background-position: right center; }
  #footer .logo-footer {
    width: 227px;
    height: 46px;
    margin-bottom: 1.3em;
    background-image: url(../img/logo_ultramar_footer.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-color: transparent; }
    #footer .logo-footer span {
      display: none; }
  #footer #frm_newsletter {
    position: relative; }
  #footer #frm_newsletter_success .title,
  #footer #frm_newsletter_success p {
    color: #08829a; }
  #footer #frm_newsletter_error {
    font-size: 0.85em; }

/*=> @HOME
 *=======================================*/
.home#footer {
  margin-top: -64px; }

.home #hero.wp-body {
  background-image: url(../img/slide-home_01_sm.jpg);
  background-color: #17286d;
  height: auto; }

.home #hero .hero-box {
  margin: 0 auto;
  width: 95%;
  height: 540px;
  text-align: right; }
  .home #hero .hero-box #hero-btn {
    top: -2em;
    position: relative; }
  .home #hero .hero-box .hero-txt {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    font-size: 3em;
    font-weight: bolder;
    text-align: left;
    width: 280px;
    height: 170px;
    overflow: hidden;
    line-height: 1.4625;
    margin-top: 0;
    margin-bottom: 0; }
    .home #hero .hero-box .hero-txt span {
      color: #6cbaca;
      font-size: 100%;
      line-height: 100%;
      display: inline-block;
      *display: inline;
      *zoom: 1; }
    .home #hero .hero-box .hero-txt span.t-1 {
      font-size: 85%; }
    .home #hero .hero-box .hero-txt span.t-2 {
      top: -0.5em;
      position: relative;
      font-size: 110%; }
    .home #hero .hero-box .hero-txt span.t-3 {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      font-size: 105%;
      width: 100%;
      top: -1em;
      position: relative;
      text-align: right; }

@media (min-width: 768px) {
  .home #hero.wp-body {
    background-image: url(../img/slide-home_01.jpg);
    height: 700px; }
  .home #hero .hero-box #hero-btn {
    top: 0em; }
  .home #hero .hero-box .hero-txt {
    width: 390px; }
    .home #hero .hero-box .hero-txt span.t-1 {
      font-size: 95%; }
    .home #hero .hero-box .hero-txt span.t-2 {
      font-size: 150%; }
    .home #hero .hero-box .hero-txt span.t-3 {
      top: -.8em;
      font-size: 140%; } }

@media (min-width: 995px) {
  .home #hero .hero-box .hero-txt {
    width: 430px; }
    .home #hero .hero-box .hero-txt span.t-1 {
      font-size: 95%; }
    .home #hero .hero-box .hero-txt span.t-2 {
      font-size: 170%; }
    .home #hero .hero-box .hero-txt span.t-3 {
      font-size: 160%; } }

@media (min-width: 1200px) {
  .home #hero .hero-box {
    width: 85%; } }

.home #rutas.wp-body {
  background-color: #fff;
  height: auto; }
  .home #rutas.wp-body #rutas-n1 {
    text-align: center;
    height: 420px;
    background-image: url(../img/mapa-playa-cozumel.jpg); }
  .home #rutas.wp-body #rutas-n2 {
    text-align: center;
    height: 320px;
    background-image: url(../img/mapa-cancun-im.jpg); }
  .home #rutas.wp-body .rutas-box {
    top: 13em;
    position: relative; }
  .home #rutas.wp-body .rutas-head {
    background-color: #f2f2f2;
    text-align: center; }
    .home #rutas.wp-body .rutas-head .rutas-head-box {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      padding: 1em 0em; }
      .home #rutas.wp-body .rutas-head .rutas-head-box a {
        margin-left: 1em; }
      .home #rutas.wp-body .rutas-head .rutas-head-box .btn-route {
        display: none; }
      .home #rutas.wp-body .rutas-head .rutas-head-box .txt {
        font-size: 1.1em;
        color: #17286d;
        vertical-align: middle;
        display: block; }
  @media (min-width: 768px) {
    .home #rutas.wp-body .rutas-box {
      top: -5em; }
    .home #rutas.wp-body #rutas-n1 {
      text-align: left;
      height: 480px; }
    .home #rutas.wp-body #rutas-n2 {
      text-align: left;
      height: 480px; }
      .home #rutas.wp-body #rutas-n2 .rutas-box {
        top: -10em; }
    .home #rutas.wp-body .rutas-head .rutas-head-box .txt {
      display: inline-block;
      *display: inline;
      *zoom: 1; } }
  @media (min-width: 995px) {
    .home #rutas.wp-body #rutas-n1 {
      height: 600px; }
    .home #rutas.wp-body #rutas-n2 {
      height: 600px; }
    .home #rutas.wp-body .rutas-head .rutas-head-box a {
      margin-left: 0.5em; } }
  @media (min-width: 1500px) {
    .home #rutas.wp-body .rutas-head .rutas-head-box .btn-route {
      display: inline-block;
      *display: inline;
      *zoom: 1; } }

html.lang-en .home #hero .hero-box .hero-txt span.t-2 {
  margin-left: 5%; }

@media (min-width: 768px) {
  html.lang-en .home #hero .hero-box .hero-txt span.t-2 {
    top: -0.48em;
    margin-left: 13%; } }

.home #tstmns.wp-body {
  padding: 3em 0em; }
  @media (min-width: 768px) {
    .home #tstmns.wp-body {
      padding: 3em 3em; } }

.home #tstmns .tstmns-icon {
  padding-bottom: 1.5em;
  background-image: url(../img/testimonios-icon_hd.png);
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 34px;
  margin: 0 auto;
  display: inline-block;
  *display: inline;
  *zoom: 1; }

.home #tstmns .tstmns-text {
  color: #54596f;
  text-align: center;
  font-size: 1.4em;
  line-height: 130%; }

.home #tstmns .tstmns-author {
  text-align: center;
  font-size: 1.4em;
  font-weight: bold;
  color: #07829b; }

.home #tstmns .tstmns-footer {
  padding: 6em 0 0 0;
  background-image: url(../img/logo_ultramar_testimonios.png);
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 40px;
  margin: 0 auto;
  display: inline-block;
  *display: inline;
  *zoom: 1; }

#prmtn {
  background-color: #283a83; }
  #prmtn .prmtn-box {
    text-align: left;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    margin: 0 auto; }
    #prmtn .prmtn-box .prmtn-cont.prmtn-left {
      background-image: url(../img/icon_prmtn_01.png);
      padding-bottom: 4em; }
    #prmtn .prmtn-box .prmtn-cont.prmtn-right {
      background-image: url(../img/icon_prmtn_02.gif);
      padding-bottom: 6em; }
    #prmtn .prmtn-box .prmtn-cont {
      background-position: left top;
      background-repeat: no-repeat;
      padding-left: 54px; }
      #prmtn .prmtn-box .prmtn-cont .title {
        color: #fff;
        font-size: 1.8em;
        font-weight: lighter;
        line-height: 120%; }
        #prmtn .prmtn-box .prmtn-cont .title span {
          font-size: 1.5em;
          color: #fee600;
          line-height: 100%;
          display: inline-block;
          *display: inline;
          *zoom: 1;
          width: 100%; }
      #prmtn .prmtn-box .prmtn-cont .title-right span {
        font-size: 1em; }
      #prmtn .prmtn-box .prmtn-cont .info {
        font-size: 1em;
        color: #FFFFFF;
        display: block; }
      @media (min-width: 768px) {
        #prmtn .prmtn-box .prmtn-cont .title {
          font-size: 1.75em; }
        #prmtn .prmtn-box .prmtn-cont .title-right span {
          font-size: 1.04em; } }
      @media (min-width: 995px) {
        #prmtn .prmtn-box .prmtn-cont .title {
          font-size: 1.8em; }
          #prmtn .prmtn-box .prmtn-cont .title span {
            font-size: 1.65em; }
        #prmtn .prmtn-box .prmtn-cont .title-right span {
          font-size: 1.18em; } }
    #prmtn .prmtn-box .prmtn-cont.prmtn-one {
      padding-left: 0;
      padding-top: 1em; }
      #prmtn .prmtn-box .prmtn-cont.prmtn-one .title {
        text-align: center;
        color: #fee600;
        font-size: 2em;
        line-height: 100%; }
        #prmtn .prmtn-box .prmtn-cont.prmtn-one .title span {
          font-size: 1em; }
      @media (min-width: 768px) {
        #prmtn .prmtn-box .prmtn-cont.prmtn-one .title {
          text-align: left;
          font-size: 2.5em; } }
      @media (min-width: 995px) {
        #prmtn .prmtn-box .prmtn-cont.prmtn-one .title {
          font-size: 3.35em; } }
      @media (min-width: 1200px) {
        #prmtn .prmtn-box .prmtn-cont.prmtn-one .title {
          font-size: 3.5em; } }
    @media (min-width: 768px) {
      #prmtn .prmtn-box .prmtn-cont,
      #prmtn .prmtn-box .prmtn-cont.prmtn-right {
        padding-bottom: 8em; }
      #prmtn .prmtn-box .prmtn-cont.prmtn-one {
        padding-top: 0; } }
  #prmtn #prmtn-l {
    padding-top: 3em;
    background-color: #283a83; }
  #prmtn #prmtn-r {
    padding-top: 3em;
    background-color: #07829b; }

/*=> @flota
 *=======================================*/
.flota #prmtn {
  background-color: #283a83;
  background-image: url(../img/prmtn_reserva_ft.jpg);
  background-position: left top;
  background-repeat: no-repeat; }
  .flota #prmtn #prmtn-one {
    height: 270px; }

.flota #bd-waves.wp-body {
  background-image: url(../img/olas-main.png);
  margin-top: -16em; }

.flota #hero.wp-body {
  background-image: url(../img/horarios-hero_bg.jpg);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-color: #17286d; }

.flota #hero {
  color: #fff; }
  .flota #hero .wp-main {
    padding-top: 4em;
    padding-bottom: 17.5em; }
  .flota #hero .hero-box {
    line-height: 120%;
    font-size: 1em; }
  .flota #hero .hero-box-one {
    background-color: transparent;
    padding: 1.8em 2.1em; }
    .flota #hero .hero-box-one .hero-title {
      font-size: 2.8em;
      line-height: 120%;
      margin-bottom: .6em; }
  .flota #hero .hero-box-two {
    margin-top: 1.5em; }
    .flota #hero .hero-box-two .button {
      margin: 0.4em 0em;
      margin-right: 0em;
      margin-left: 1.5em; }
    .flota #hero .hero-box-two .button.btn-reserva {
      padding-left: 4.6em;
      padding-right: 4.6em; }
  @media (min-width: 768px) {
    .flota #hero .hero-box-two {
      margin-top: 6.5em; }
      .flota #hero .hero-box-two .button {
        margin-right: 1em;
        margin-left: 0em; }
      .flota #hero .hero-box-two .button.btn-reserva {
        padding-left: 5.6em;
        padding-right: 5.6em; } }

@media (min-width: 768px) {
  html.lang-en .flota #hero .button {
    padding-left: 5.4em;
    padding-right: 5.4em; }
  html.lang-en .flota #hero .button.btn-reserva {
    padding-left: 3.7em;
    padding-right: 3.7em; } }

.flota #ourfloat .wp-main {
  padding: 0em 0em 6em 0em; }

.flota .float .float-zone:hover .float-box {
  background-color: #f2f2f2; }

.flota .float .float-zone .float-box {
  display: block;
  background-color: #f2f2f2;
  padding: 1em 1em 1em 1em;
  margin-bottom: 20px;
  cursor: default; }
  .flota .float .float-zone .float-box .float-title {
    font-size: 2em;
    color: #17286d;
    padding-bottom: 0.35em;
    border-bottom: 1px solid #dcdfeb;
    position: relative; }
    .flota .float .float-zone .float-box .float-title span {
      font-size: .43em;
      line-height: 120%;
      display: block;
      position: relative;
      bottom: 0em;
      text-align: left;
      width: 100%;
      right: 0;
      color: #08829a; }
    @media (min-width: 768px) {
      .flota .float .float-zone .float-box .float-title span {
        text-align: right;
        bottom: 1em;
        position: absolute;
        width: 50%; } }
    @media (min-width: 995px) {
      .flota .float .float-zone .float-box .float-title span {
        position: absolute;
        width: 45%; } }
    @media (min-width: 1200px) {
      .flota .float .float-zone .float-box .float-title span {
        position: absolute;
        width: 50%; } }
  .flota .float .float-zone .float-box ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    line-height: 100%; }
    .flota .float .float-zone .float-box ul li {
      float: none;
      padding: 0;
      margin: 0.5em 0 0.5em 0;
      line-height: 100%;
      width: 100%;
      border-right: 0px solid #dcdfeb;
      text-align: left; }
      @media (min-width: 768px) {
        .flota .float .float-zone .float-box ul li {
          margin: 1em 0 0 0;
          float: left;
          width: 33.333%;
          text-align: center;
          border-right: 1px solid #dcdfeb; } }
      .flota .float .float-zone .float-box ul li .float-cont {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        margin: 0 auto;
        padding-bottom: 0em;
        text-align: left;
        width: 100%; }
        .flota .float .float-zone .float-box ul li .float-cont .t {
          color: #17286d;
          float: left;
          width: 40%; }
        .flota .float .float-zone .float-box ul li .float-cont .n {
          margin-top: 0em;
          color: #08829a;
          font-size: 1em;
          float: left;
          width: 50%; }
          .flota .float .float-zone .float-box ul li .float-cont .n span {
            font-size: 100%; }
        @media (min-width: 768px) {
          .flota .float .float-zone .float-box ul li .float-cont {
            padding-bottom: 1em; }
            .flota .float .float-zone .float-box ul li .float-cont .t {
              width: 100%;
              float: none; }
            .flota .float .float-zone .float-box ul li .float-cont .n {
              margin-top: 0.35em;
              font-size: 1.5em;
              width: 100%;
              float: none; }
              .flota .float .float-zone .float-box ul li .float-cont .n span {
                font-size: 70%; } }
    .flota .float .float-zone .float-box ul li:first-child {
      text-align: left; }
    .flota .float .float-zone .float-box ul li.last-child {
      border-right: 0px solid #dcdfeb; }

@media (min-width: 768px) {
  .flota .float .float-zone .float-box {
    padding: 2em 3em 1em 3em; } }

/*=> @TARIFAS
 *=======================================*/
body.tarifas #hourlist .wp-main {
  padding: 0em 0em 6em 0em; }

body.tarifas #hourlist ol.list-root,
body.tarifas #hourlist ul.list-node {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  body.tarifas #hourlist ol.list-root li,
  body.tarifas #hourlist ul.list-node li {
    padding: 0;
    margin: 0;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    line-height: 100%;
    list-style-type: none;
    width: 100%; }

body.tarifas #hourlist ol.list-root .title-root {
  padding: 1em 0em;
  width: 100%;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-size: 1.8em;
  line-height: 100%;
  color: #08829a; }

body.tarifas #hourlist ol.list-root li.li-root {
  border-top: solid 0px #08829a;
  padding: 0em;
  display: block; }

body.tarifas #hourlist ol.list-root li.li-root.li-root-bottom {
  border-bottom: solid 0px #dcdfeb;
  padding-bottom: 0em;
  margin-bottom: 3em; }

@media (min-width: 995px) {
  body.tarifas #hourlist ol.list-root li.li-root.li-root-bottom {
    border-bottom: solid 4px #dcdfeb;
    padding-bottom: 1em;
    margin-bottom: 3em; } }

body.tarifas #hourlist ol.list-root li.li-root.li-l0 ul.list-node ul.list-rate {
  border-top: solid 0px #dcdfeb;
  padding: 0 0 0 0; }

body.tarifas #hourlist ol.list-root li.li-root.li-l1 ul.list-node ul.list-child {
  min-height: auto; }

body.tarifas #hourlist ul.list-node li.li-node {
  padding: 0.8em 0em 0em 0em;
  display: block;
  border-right: solid 0px #dcdfeb;
  float: none;
  width: 100%; }
  body.tarifas #hourlist ul.list-node li.li-node .pager-node {
    width: 100%;
    padding-bottom: 0em;
    margin-bottom: 0.5em;
    border-bottom: solid 0px #dcdfeb; }

@media (min-width: 995px) {
  body.tarifas #hourlist ul.list-node li.li-node {
    float: left;
    width: 50%;
    border-right: solid 1px #dcdfeb; }
    body.tarifas #hourlist ul.list-node li.li-node .pager-node {
      width: 94%;
      padding-bottom: 1em;
      border-bottom: solid 4px #dcdfeb; } }

body.tarifas #hourlist ul.list-node li.li-node.li-node-last {
  float: none;
  border-right: solid 0px #dcdfeb; }
  body.tarifas #hourlist ul.list-node li.li-node.li-node-last .pager-node {
    width: 100%;
    margin-left: 0%; }

@media (min-width: 995px) {
  body.tarifas #hourlist ul.list-node li.li-node.li-node-last {
    float: right; }
    body.tarifas #hourlist ul.list-node li.li-node.li-node-last .pager-node {
      width: 90%;
      margin-left: 10%; } }

body.tarifas #hourlist ul.list-node li.li-node.li-node-bottom .pager-node {
  padding-bottom: 0em;
  border-bottom: solid 0px #dcdfeb; }

body.tarifas #hourlist ul.list-node li.li-node.li-node-bottom-x .pager-node {
  padding-bottom: 6em;
  border-bottom: solid 0px #dcdfeb; }

body.tarifas #hourlist ul.list-node .box-node {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0em 0em 0em 0em;
  width: 100%;
  position: relative; }
  body.tarifas #hourlist ul.list-node .box-node .name_in,
  body.tarifas #hourlist ul.list-node .box-node .name_out {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    width: 55%;
    color: #54596f;
    padding: 0.8em 0.15em 0.8em 0.15em; }
  body.tarifas #hourlist ul.list-node .box-node .name_out {
    text-align: right;
    width: 25%; }
  body.tarifas #hourlist ul.list-node .box-node .icon-in_out {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    width: 20%;
    height: auto;
    padding: 0.8em 0em;
    background-image: url(../img/icon-in_out.png);
    background-position: center center;
    background-repeat: no-repeat; }
  body.tarifas #hourlist ul.list-node .box-node .hero-title {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    position: absolute;
    float: none;
    top: 0;
    left: 5em;
    text-align: right;
    width: 60%;
    padding: 0em 1em 0em 0em; }
  body.tarifas #hourlist ul.list-node .box-node .hero-button {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    border: 0px solid #343434;
    font-weight: bold;
    font-size: 0.9em;
    padding: 0.7em 2.3em 0.7em 2.3em;
    margin: 0.15em 0em 0.65em 0em;
    border-radius: 2em 2em 2em 2em;
    -moz-border-radius: 2em 2em 2em 2em;
    -webkit-border-radius: 2em 2em 2em 2em;
    color: #17286d;
    background-color: #fee600; }

body.tarifas #hourlist ul.list-node ul.list-child {
  display: block;
  width: 100%;
  padding: 0em 0 0 0;
  margin: 0em; }
  body.tarifas #hourlist ul.list-node ul.list-child .li-child {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 100%;
    padding: 0em 0em;
    min-height: auto; }
    body.tarifas #hourlist ul.list-node ul.list-child .li-child .child-title-big {
      display: block;
      color: #08829a;
      font-size: 1.3em;
      margin-bottom: 1.5em; }
    body.tarifas #hourlist ul.list-node ul.list-child .li-child .child-title {
      float: left;
      display: block;
      color: #54596f;
      font-size: 1em;
      width: 28%; }

body.tarifas #hourlist ul.list-node ul.list-rate.list-rate-last {
  margin: 0em 0em 2em 0em; }

body.tarifas #hourlist ul.list-node ul.list-rate {
  display: block;
  width: 100%;
  padding: 1.3em 0 0 0;
  margin: 0em 0em 1.3em 0em;
  border-top: solid 1px #dcdfeb; }
  body.tarifas #hourlist ul.list-node ul.list-rate li {
    float: left;
    width: 36%; }
  body.tarifas #hourlist ul.list-node ul.list-rate li.li-hd {
    width: 28%; }
  body.tarifas #hourlist ul.list-node ul.list-rate .rate-title {
    font-weight: lighter;
    display: block; }
  body.tarifas #hourlist ul.list-node ul.list-rate .rate-price {
    font-size: 2.3em;
    line-height: 100%;
    color: #08829a; }
    body.tarifas #hourlist ul.list-node ul.list-rate .rate-price span {
      font-size: 0.5em;
      vertical-align: top; }

body.tarifas #hourlist ol.list-root .note-block {
  display: block;
  padding: 0;
  text-align: center;
  font-size: .85em; }

body.tarifas #hourlist ol.list-root li.li-root .info {
  min-height: 8em;
  padding: 1em 0em 1em 0em;
  text-align: left;
  font-size: .85em; }
  body.tarifas #hourlist ol.list-root li.li-root .info p.note {
    color: #54596f; }

@media (min-width: 768px) {
  body.tarifas #hourlist ol.list-root li.li-root .info {
    min-height: 12em; } }

@media (min-width: 995px) {
  body.tarifas #hourlist ol.list-root li.li-root .info {
    min-height: 10em; } }

@media (min-width: 1200px) {
  body.tarifas #hourlist ol.list-root li.li-root .info {
    min-height: 8em; } }

body.tarifas #hourlist ol.list-root li.li-root.li-l0 li.li-node.li-node-last {
  border-top: 1px solid #dcdfeb; }

body.tarifas #hourlist ol.list-root li.li-root.li-l0 .info {
  padding: 0em 0em 0em 0em;
  text-align: left;
  min-height: auto; }
  body.tarifas #hourlist ol.list-root li.li-root.li-l0 .info p {
    margin-bottom: 0; }
  body.tarifas #hourlist ol.list-root li.li-root.li-l0 .info p.note {
    margin-top: 0.35em; }

@media (min-width: 995px) {
  body.tarifas #hourlist ol.list-root li.li-root.li-l0 li.li-node.li-node-last {
    border-top: 0px solid #dcdfeb; }
  body.tarifas #hourlist ol.list-root li.li-root.li-l0 .info {
    text-align: center; }
    body.tarifas #hourlist ol.list-root li.li-root.li-l0 .info p.note {
      margin-top: 0; } }

/*=> @HORARIOS
 *=======================================*/
@media (min-width: 995px) {
  html.lang-en .horarios #hourlist ul.list-node .box-node .route {
    width: 20%; }
  html.lang-en .horarios #hourlist ul.list-node .box-node .name_out {
    width: 14%; }
  html.lang-en .horarios #hourlist ul.list-node .box-node .hero-title {
    width: 25%;
    left: 0%;
    right: 0%; } }

.horarios #prmtn {
  background-color: #283a83;
  background-image: url(../img/prmtn_reserva_ft.jpg);
  background-position: left top;
  background-repeat: no-repeat; }
  .horarios #prmtn #prmtn-one {
    height: 270px; }

.horarios #bd-waves.wp-body {
  background-image: url(../img/olas-main.png);
  margin-top: -16em; }

.horarios #hero.wp-body {
  background-image: url(../img/horarios-hero_bg.jpg);
  background-position: center bottom;
  background-repeat: repeat-x;
  background-color: #17286d; }

.horarios #hero {
  color: #fff; }
  .horarios #hero .wp-main {
    padding-top: 4em;
    padding-bottom: 17.5em; }
  .horarios #hero .hero-box {
    line-height: 120%;
    font-size: 1em; }
  .horarios #hero .hero-box-one {
    background-color: transparent;
    padding: 1.8em 2.1em; }
    .horarios #hero .hero-box-one .hero-title {
      font-size: 2.8em;
      line-height: 120%;
      margin-bottom: .6em; }
  .horarios #hero .hero-box-two {
    margin-top: 1.5em;
    background-color: rgba(7, 130, 155, 0.5);
    padding: 1em 1.5em;
    border-radius: 0.55em; }
    .horarios #hero .hero-box-two .hero-title {
      font-size: 1.7em;
      line-height: 120%;
      margin-bottom: .45em;
      color: #fee600; }
    .horarios #hero .hero-box-two p {
      font-size: 0.85em; }
    .horarios #hero .hero-box-two .phone,
    .horarios #hero .hero-box-two .mailto {
      color: #fff; }
  @media (min-width: 768px) {
    .horarios #hero .hero-box-two {
      margin-top: 4.5em;
      padding: 1.5em 3em; } }

.horarios #hourlist .wp-main {
  padding: 0em 0em 6em 0em; }

.horarios #hourlist ol.list-root,
.horarios #hourlist ul.list-node {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .horarios #hourlist ol.list-root li,
  .horarios #hourlist ul.list-node li {
    padding: 0;
    margin: 0;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    line-height: 100%;
    list-style-type: none;
    width: 100%; }

.horarios #hourlist ol.list-root .title-root {
  padding: 1em 1em;
  width: 100%;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  font-size: 1.8em;
  line-height: 100%;
  color: #08829a; }

.horarios #hourlist ol.list-root li.li-root {
  border-top: solid 5px #08829a;
  padding: 0em;
  display: block; }

.horarios #hourlist ul.list-node li.li-node {
  border-top: solid 1px #dcdfeb;
  padding: 0.8em 0em 0em 0em;
  display: block; }
  .horarios #hourlist ul.list-node li.li-node:hover {
    background-color: #f1f4ff; }
  .horarios #hourlist ul.list-node li.li-node ul.list-child {
    height: 0;
    overflow: hidden;
    opacity: 0; }
    .horarios #hourlist ul.list-node li.li-node ul.list-child .list-child-box {
      background-color: #fff; }

.horarios #hourlist ul.list-node li.node-last {
  border-bottom: solid 1px #dcdfeb; }

.horarios #hourlist ul.list-node .box-node {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  padding: 0em 1em 0em 1em;
  width: 100%;
  position: relative; }
  .horarios #hourlist ul.list-node .box-node .route {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    color: #08829a;
    width: 100%;
    padding: 0.8em 0em 0.6em 0em;
    font-size: 1.1em; }
  .horarios #hourlist ul.list-node .box-node .name_in,
  .horarios #hourlist ul.list-node .box-node .name_out {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    width: 40%;
    color: #54596f;
    padding: 0.8em 0em 0.8em 1em; }
  .horarios #hourlist ul.list-node .box-node .name_in {
    padding-left: 0em; }
  .horarios #hourlist ul.list-node .box-node .name_out {
    width: 40%; }
  .horarios #hourlist ul.list-node .box-node .icon-in_out {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    width: 20%;
    height: auto;
    padding: 0.8em 0em;
    background-image: url(../img/icon-in_out.png);
    background-position: center center;
    background-repeat: no-repeat; }
  .horarios #hourlist ul.list-node .box-node .hero-title {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    position: relative;
    float: none;
    top: 0;
    left: auto;
    text-align: center;
    width: 100%;
    padding: 0em 1em 0em 0em; }
  .horarios #hourlist ul.list-node .box-node .icon-arrow_down {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    position: absolute;
    float: none;
    top: 0em;
    right: 1em;
    width: 36px;
    background-image: url(../img/icon-arrow_down.png);
    background-position: center right;
    background-repeat: no-repeat;
    padding: 0.8em 0em; }
    .horarios #hourlist ul.list-node .box-node .icon-arrow_down:hover {
      cursor: pointer;
      background-position: center left; }
  .horarios #hourlist ul.list-node .box-node .hero-button {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    border: 0px solid #343434;
    font-weight: bold;
    font-size: 0.9em;
    padding: 0.7em 2.3em 0.7em 2.3em;
    margin: 0.15em 0em 0.65em 0em;
    border-radius: 2em 2em 2em 2em;
    -moz-border-radius: 2em 2em 2em 2em;
    -webkit-border-radius: 2em 2em 2em 2em;
    color: #17286d;
    background-color: #fee600; }

@media (min-width: 768px) {
  .horarios #hourlist ul.list-node .box-node .hero-title {
    left: 5em;
    position: absolute;
    width: 60%;
    text-align: right; } }

@media (min-width: 995px) {
  .horarios #hourlist ul.list-node .box-node {
    padding: 0em 2em 0em 2em; }
    .horarios #hourlist ul.list-node .box-node .route {
      width: 8%; }
    .horarios #hourlist ul.list-node .box-node .name_in {
      width: 25%;
      padding-left: 1em; }
    .horarios #hourlist ul.list-node .box-node .name_out {
      width: 18%; }
    .horarios #hourlist ul.list-node .box-node .icon-in_out {
      width: 8%; }
    .horarios #hourlist ul.list-node .box-node .hero-title {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      position: relative;
      top: 0;
      left: 0;
      float: left;
      width: 33%; }
    .horarios #hourlist ul.list-node .box-node .icon-arrow_down {
      position: relative;
      float: right; } }

.horarios #hourlist ul.list-node ul.list-child {
  border-top: solid 1px #dcdfeb;
  display: block;
  width: 100%;
  padding: 0em;
  margin: 0em; }
  .horarios #hourlist ul.list-node ul.list-child ul {
    padding: 0;
    margin: 0; }
  .horarios #hourlist ul.list-node ul.list-child .li-child {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    float: left;
    border-right: solid 1px #dcdfeb;
    border-top: solid 1px #dcdfeb;
    width: 100%;
    padding: 1em 1em;
    min-height: auto; }
    .horarios #hourlist ul.list-node ul.list-child .li-child .child-title {
      display: block;
      color: #17286d;
      font-size: 1.1em;
      margin-bottom: 1.5em; }
    .horarios #hourlist ul.list-node ul.list-child .li-child ul.list-rate .child-title {
      display: block;
      margin-bottom: 0.15em; }
    .horarios #hourlist ul.list-node ul.list-child .li-child ul.list-rate li.li-hd {
      display: block; }
  @media (min-width: 768px) {
    .horarios #hourlist ul.list-node ul.list-child .li-child {
      border-top: solid 0px #dcdfeb;
      min-height: auto; }
      .horarios #hourlist ul.list-node ul.list-child .li-child .child-title {
        display: inline-block;
        *display: inline;
        *zoom: 1; }
    .horarios #hourlist ul.list-node ul.list-child .li-child.rate {
      border-top: solid 0px #dcdfeb; } }
  .horarios #hourlist ul.list-node ul.list-child .li-child.last-child {
    border-right: 0px;
    width: 100%;
    border-top: solid 1px #dcdfeb;
    min-height: auto; }
  .horarios #hourlist ul.list-node ul.list-child .li-child.li-child-two {
    border-top: solid 1px #dcdfeb;
    border-right: 0px;
    width: 100%; }
    .horarios #hourlist ul.list-node ul.list-child .li-child.li-child-two .title {
      color: #17286d; }
  @media (min-width: 768px) {
    .horarios #hourlist ul.list-node ul.list-child .li-child {
      width: 33.333%;
      padding: 2em 2em; }
    .horarios #hourlist ul.list-node ul.list-child .li-child.schedule {
      padding: 2em 0em; }
      .horarios #hourlist ul.list-node ul.list-child .li-child.schedule .child-title {
        padding-left: 2em; }
    .horarios #hourlist ul.list-node ul.list-child .li-child.last-child {
      width: 33.333%;
      border-right: solid 0px #dcdfeb;
      border-top: solid 0px #dcdfeb;
      min-height: 330px; }
    .horarios #hourlist ul.list-node ul.list-child .li-child.li-child-two {
      width: 66.666%; } }

.horarios #hourlist ul.list-node li.li-node.open {
  background-color: #f1f4ff; }
  .horarios #hourlist ul.list-node li.li-node.open .box-node .icon-arrow_down {
    background-image: url(../img/icon-arrow_up.png); }
  .horarios #hourlist ul.list-node li.li-node.open ul.list-child {
    height: 100%;
    opacity: 1;
    transition: opacity 0.5s ease-in; }

.horarios #hourlist ul.list-node li.li-node.close {
  -ms-user-select: none;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none; }

.horarios #hourlist ul.list-node ul.list-schdl {
  display: block;
  float: left;
  width: 50%;
  padding: 0em;
  margin: 0em;
  text-align: center; }
  .horarios #hourlist ul.list-node ul.list-schdl .icon-ship_go {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 100%;
    height: 42px;
    background-image: url(../img/icon-ship_go_noarrow.png);
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 1em; }
  .horarios #hourlist ul.list-node ul.list-schdl .icon-ship_return {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    width: 100%;
    height: 42px;
    background-image: url(../img/icon-ship_return_noarrow.png);
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 1em; }
  .horarios #hourlist ul.list-node ul.list-schdl .li-schdl {
    padding: 0em 0.5em; }
    .horarios #hourlist ul.list-node ul.list-schdl .li-schdl ul {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      width: 100%;
      padding: 0em;
      margin: 0em; }
      .horarios #hourlist ul.list-node ul.list-schdl .li-schdl ul li {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        width: 100%; }
        .horarios #hourlist ul.list-node ul.list-schdl .li-schdl ul li span {
          padding-right: 0.5em; }

.horarios #hourlist ul.list-node .list-schdl-go .li-schdl {
  border-right: 1px solid #54596f; }

.horarios #hourlist ul.list-node .list-schdl-return .li-schdl {
  margin-left: 0.5em; }

@media (min-width: 995px) {
  .horarios #hourlist ul.list-node ul.list-schdl {
    width: 46%;
    text-align: center; }
  .horarios #hourlist ul.list-node ul.list-schdl.list-schdl-go {
    margin-left: 2%;
    margin-right: 2%; }
  .horarios #hourlist ul.list-node ul.list-schdl.list-schdl-return {
    margin-left: 2%;
    margin-right: 2%; } }

.horarios #hourlist ul.list-node ul.list-rate {
  display: block;
  width: 100%;
  padding: 0em;
  margin: 0em 0em 1em 0em; }
  .horarios #hourlist ul.list-node ul.list-rate li {
    float: left;
    width: 50%; }
  .horarios #hourlist ul.list-node ul.list-rate li.li-hd {
    width: 100%;
    float: none; }
  .horarios #hourlist ul.list-node ul.list-rate .rate-title {
    font-weight: lighter;
    display: block; }
  .horarios #hourlist ul.list-node ul.list-rate .rate-price {
    font-size: 2.3em;
    line-height: 100%;
    color: #08829a; }
    .horarios #hourlist ul.list-node ul.list-rate .rate-price span {
      font-size: 0.5em;
      vertical-align: top; }

@media (min-width: 768px) {
  .horarios #hourlist ul.list-node ul.list-rate li {
    float: left;
    width: 50%; }
  .horarios #hourlist ul.list-node ul.list-rate li.li-hd {
    width: 100%;
    float: none; } }

.horarios #hourlist ul.list-node ul.list-departure {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: 100%;
  padding: 0em 0em 0em 0em;
  margin: 0em 0em 1em 0em; }
  .horarios #hourlist ul.list-node ul.list-departure li {
    position: relative;
    padding: 0em 0em 0em 2em; }
    .horarios #hourlist ul.list-node ul.list-departure li .button-sm {
      padding: 0.25em 2.9em 0.35em 2.9em; }
    .horarios #hourlist ul.list-node ul.list-departure li .buticon {
      position: absolute;
      left: -0.35em;
      top: -0.15em; }

@media (min-width: 768px) {
  .horarios #hourlist ul.list-node ul.list-departure {
    display: block;
    width: 100%; } }

.horarios #hourlist ul.list-node .list-child-box {
  position: relative; }

.horarios #hourlist ul.list-node .list-child-modal.close {
  display: none; }

.horarios #hourlist ul.list-node .list-child-modal.open {
  display: block; }

.horarios #hourlist ul.list-node .list-child-modal {
  position: absolute;
  display: block;
  margin: 0;
  padding: 0;
  background-color: #f3f5ff;
  width: 100%;
  height: auto;
  z-index: 99; }
  .horarios #hourlist ul.list-node .list-child-modal .lcm-close {
    z-index: 999;
    position: absolute;
    top: 50%;
    right: 7%;
    margin: -16px 0em 0em 0em;
    display: block;
    width: 32px;
    height: 32px;
    overflow: hidden;
    background-image: url(../img/modal-close.png);
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center; }
  .horarios #hourlist ul.list-node .list-child-modal .lcm-hd {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
    padding: 1.5em 0em 1.7em 0em;
    border-bottom: 1px solid #dcdfeb; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-hd span {
      font-size: 1.2em;
      color: #08829a; }
    @media (min-width: 768px) {
      .horarios #hourlist ul.list-node .list-child-modal .lcm-hd {
        padding: 2.5em 0em 2.7em 0em; } }
  .horarios #hourlist ul.list-node .list-child-modal .lcm-nav {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
    padding: 0.25em 0em 0.85em 0em;
    border-bottom: 1px solid #dcdfeb; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a {
      float: none;
      display: block;
      margin: 0;
      padding: 0.5em 0;
      font-weight: 700;
      font-size: 0.9em;
      width: 100%;
      color: #b5b5b5;
      text-align: left;
      transition: color 0.5s ease-in; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a:hover,
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav .active:hover,
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav .active {
      color: #17286d;
      transition: color 0.5s ease-in; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a.first-child {
      text-align: left;
      width: 100%; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a.last-child {
      text-align: left;
      width: 100%; }
    @media (min-width: 768px) {
      .horarios #hourlist ul.list-node .list-child-modal .lcm-nav {
        padding: 1.25em 0em 1.85em 0em; }
        .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a {
          float: left;
          width: 30%;
          padding: 0em;
          text-align: center;
          margin-right: 0em; }
        .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a.first-child {
          text-align: left;
          width: 20%; }
        .horarios #hourlist ul.list-node .list-child-modal .lcm-nav a.last-child {
          text-align: right;
          width: 20%; } }
  .horarios #hourlist ul.list-node .list-child-modal .lcm-body {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .lcm-slide.close {
      display: none; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .lcm-slide.open {
      display: block;
      position: relative;
      left: 0;
      opacity: 1;
      animation: kf_pushFx_right 0.5s ease-in-out; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .lcm-slide {
      opacity: 0;
      left: 100%;
      display: none;
      position: absolute;
      padding: 0.5em 0em 0.7em 0em;
      width: 100%;
      margin: 0em; }
      @media (min-width: 768px) {
        .horarios #hourlist ul.list-node .list-child-modal .lcm-body .lcm-slide {
          padding: 2.5em 0em 2.7em 0em; } }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .icon-auto {
      margin: 2em 0em 0.5em 0em;
      display: block;
      width: 96px;
      height: 60px;
      overflow: hidden;
      background-image: url(../img/icon-auto.png);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: left center; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .icon-colective {
      margin: 2em 0em 0.5em 0em;
      display: block;
      width: 96px;
      height: 60px;
      overflow: hidden;
      background-image: url(../img/icon-colective.png);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: left center; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .icon-taxi {
      margin: 2em 0em 0.5em 0em;
      display: block;
      width: 96px;
      height: 60px;
      overflow: hidden;
      background-image: url(../img/icon-taxi.png);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: left center; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .icon-bus {
      margin: 2em 0em 0.5em 0em;
      display: block;
      width: 120px;
      height: 60px;
      overflow: hidden;
      background-image: url(../img/icon-bus.png);
      background-repeat: no-repeat;
      background-color: transparent;
      background-position: left center; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body .title {
      color: #08829a;
      padding: 0.5em 0em; }
    .horarios #hourlist ul.list-node .list-child-modal .lcm-body p {
      color: #54596f;
      line-height: 135%;
      text-align: justify;
      text-justify: inter-word;
      text-align-last: left; }

.horarios #hourlist ul.list-node .mapa-ruta {
  display: block;
  height: 454px; }
  .horarios #hourlist ul.list-node .mapa-ruta .btn-reserva {
    margin: 6% 0% 0% 4%; }

.horarios #hourlist ul.list-node .mapa-ruta.mapa-ruta_01 {
  background-image: url(../img/ruta-playa-cozumel.jpg); }

.horarios #hourlist ul.list-node .mapa-ruta.mapa-ruta_02 {
  background-image: url(../img/ruta-juarez-im.jpg); }

.horarios #hourlist ul.list-node .mapa-ruta.mapa-ruta_03 {
  background-image: url(../img/ruta-tortugas-im.jpg); }

.horarios #hourlist ul.list-node .mapa-ruta.mapa-ruta_04 {
  background-image: url(../img/ruta-embarcadero-im.jpg); }

.horarios #hourlist ul.list-node .mapa-ruta.mapa-ruta_05 {
  background-image: url(../img/ruta-caracol-im.jpg); }

.horarios #hourlist ul.list-node .mapa-ruta.cover-size {
  height: 240px; }

@media (min-width: 768px) {
  .horarios #hourlist ul.list-node .mapa-ruta.cover-size {
    height: 454px; } }

@media (min-width: 768px) {
  html.lang-en .horarios #hourlist ul.list-node .list-child-modal#lcm-modal-1 .lcm-nav {
    padding: 1.25em 0em 0.85em 0em; }
    html.lang-en .horarios #hourlist ul.list-node .list-child-modal#lcm-modal-1 .lcm-nav a {
      width: auto;
      text-align: center;
      margin-right: 2em;
      padding-bottom: 1em; }
    html.lang-en .horarios #hourlist ul.list-node .list-child-modal#lcm-modal-1 .lcm-nav a.first-child {
      width: auto;
      text-align: center; }
    html.lang-en .horarios #hourlist ul.list-node .list-child-modal#lcm-modal-1 .lcm-nav a.last-child {
      width: auto;
      text-align: center; } }

/*=> @PRIVACY
 *=======================================*/
@media (min-width: 768px) {
  .privacy .aboutus #hero-dwn .wp-main {
    padding-bottom: 0em; } }

.privacy #privacy-post {
  margin-top: 0em; }
  @media (min-width: 768px) {
    .privacy #privacy-post {
      margin-top: -4em; } }

/*=> @ABOUTUS
 *=======================================*/
.aboutus #hero-dwn {
  background-color: #17286d; }
  .aboutus #hero-dwn .wp-main {
    padding: 4em 0em 0em 0em; }
  .aboutus #hero-dwn .hero-box {
    color: #fff; }
    .aboutus #hero-dwn .hero-box .hero-sub {
      color: #07829b;
      padding-bottom: 0.5em; }
    .aboutus #hero-dwn .hero-box .hero-title {
      font-size: 2.3em;
      line-height: 100%;
      padding-bottom: 0.5em; }
    .aboutus #hero-dwn .hero-box .phone {
      color: #fff;
      font-size: 2.3em;
      font-weight: lighter;
      line-height: 100%; }
    .aboutus #hero-dwn .hero-box p {
      font-weight: lighter; }
    .aboutus #hero-dwn .hero-box .buttons {
      padding-top: 1.2em; }
      .aboutus #hero-dwn .hero-box .buttons a.btn-route {
        margin-right: 1em; }
  @media (min-width: 768px) {
    .aboutus #hero-dwn .wp-main {
      padding: 6em 0em 4em 0em; }
    .aboutus #hero-dwn .wp-main .hero-title {
      font-size: 2.8em; } }
  @media (min-width: 995px) {
    .aboutus #hero-dwn .wp-main .hero-title {
      font-size: 3em; } }

/*=> @CONTACTO
 *=======================================*/
.contacto #hero-up.wp-body {
  background-color: #203176;
  background-image: url(../img/mapa-contacto.jpg);
  height: 120px; }

@media (min-width: 768px) {
  .contacto #hero-up.wp-body {
    height: 180px; } }

@media (min-width: 995px) {
  .contacto #hero-up.wp-body {
    height: 260px; } }

@media (min-width: 1200px) {
  .contacto #hero-up.wp-body {
    height: 300px; } }

.contacto #hero-dwn.wp-body {
  background-color: #17286d; }

.contacto #hero-dwn .wp-main {
  padding: 4em 0em 1em 0em; }

.contacto #hero-dwn .hero-box {
  color: #fff; }
  .contacto #hero-dwn .hero-box .hero-sub {
    color: #07829b;
    padding-bottom: 0.5em; }
  .contacto #hero-dwn .hero-box .hero-title {
    font-size: 2.3em;
    line-height: 100%;
    padding-bottom: 0.5em; }
  .contacto #hero-dwn .hero-box .phone {
    color: #fff;
    font-size: 2.3em;
    font-weight: lighter;
    line-height: 100%; }
  .contacto #hero-dwn .hero-box p {
    font-weight: lighter; }

@media (min-width: 768px) {
  .contacto #hero-dwn .wp-main {
    padding: 6em 0em; }
  .contacto #hero-dwn .wp-main .hero-title {
    font-size: 2.8em; } }

@media (min-width: 995px) {
  .contacto #hero-dwn .wp-main .hero-title {
    font-size: 3em; } }

.contacto #hero.wp-body {
  background-color: #203176;
  /*background-image: url(../img/mapa-contacto.jpg)*/
  height: 770px; }

.contacto #hero {
  color: #fff; }
  .contacto #hero .hero-box {
    background-color: rgba(7, 130, 155, 0.7);
    padding: 1.8em 2.1em;
    border-radius: 0.55em; }
    .contacto #hero .hero-box .hero-title {
      font-size: 2em;
      line-height: 120%;
      margin-bottom: .3em; }
    .contacto #hero .hero-box .phone {
      font-size: 2em;
      color: #fff; }

.contacto #prmtn {
  background-color: #283a83;
  background-image: url(../img/prmtn_reserva_ft.jpg);
  background-position: center top;
  background-repeat: no-repeat; }
  .contacto #prmtn #prmtn-one {
    height: 270px; }

@media (min-width: 768px) {
  .contacto #prmtn {
    background-position: 12% top; } }

@media (min-width: 995px) {
  .contacto #prmtn {
    background-position: 4% top; } }

@media (min-width: 1200px) {
  .contacto #prmtn {
    background-position: 0% top; } }

.contacto #mailto.wp-body {
  margin-top: -1em;
  padding-top: 0em; }
  @media (min-width: 768px) {
    .contacto #mailto.wp-body {
      margin-top: -4em; } }
  @media (min-width: 995px) {
    .contacto #mailto.wp-body {
      margin-top: -6em;
      padding-top: 0em; } }

.contacto #frm_contact_success .icon_thanks {
  height: 120px;
  width: 100%;
  background-image: url(../img/icon_form_thanks.png);
  background-position: center center;
  background-repeat: no-repeat; }

.contacto #frm_contact_success h1 {
  color: #08829a; }

.contacto #frm_contact_success p {
  color: #08829a;
  font-size: 1.2em; }

.contacto #frm_contact_error {
  position: relative;
  margin: 0 auto;
  text-align: center; }

.contacto .form-box {
  background-position: center bottom;
  background-repeat: no-repeat;
  border-radius: 0.75em;
  padding: 3.2em 2em; }
  .contacto .form-box .form-error {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    padding: 1em 2em;
    margin: 1em auto 1em auto;
    border-radius: 1em 1em 1em 1em;
    background-color: #07829b; }
    .contacto .form-box .form-error ul {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      padding: 0;
      margin: 0;
      list-style-type: none;
      color: #FFFFFF; }
  .contacto .form-box .button-group {
    text-align: center;
    margin-top: 2.5em; }
  .contacto .form-box textarea.form-control {
    border: 0px solid #ffffff;
      border-box-shadow: inset 0 0px 0px transparent;
    -webkit-box-shadow: inset 0 0px 0px transparent; }
  .contacto .form-box input.form-control,
  .contacto .form-box textarea.form-control {
    border-bottom: 1px solid #54596f;
    color: #54596f; }
    .contacto .form-box input.form-control:hover, .contacto .form-box input.form-control:focus,
    .contacto .form-box textarea.form-control:hover,
    .contacto .form-box textarea.form-control:focus {
      color: #07829b;
      border-bottom: 1px solid #07829b; }
  .contacto .form-box .form-control:hover:-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:hover::-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:hover:-ms-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:hover::-webkit-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:focus:-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:focus::-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:focus:-ms-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:focus::-webkit-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control:-moz-placeholder {
    color: #54596f; }
  .contacto .form-box .form-control::-moz-placeholder {
    color: #54596f; }
  .contacto .form-box .form-control:-ms-input-placeholder {
    color: #54596f; }
  .contacto .form-box .form-control::-webkit-input-placeholder {
    color: #54596f; }
  .contacto .form-box input.form-control.error,
  .contacto .form-box textarea.form-control.error {
    border-bottom: 1px solid #07829b;
    color: #07829b; }
  .contacto .form-box .form-control.error:-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control.error::-moz-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control.error:-ms-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-control.error::-webkit-input-placeholder {
    color: #07829b; }
  .contacto .form-box .form-group {
    position: relative; }
    .contacto .form-box .form-group .icon-validator {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0;
      top: 0;
      overflow: hidden; }
      .contacto .form-box .form-group .icon-validator span {
        display: inline-block;
        *display: inline;
        *zoom: 1;
        position: relative;
        top: 0;
        background-position: center center;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        left: 0%; }
    .contacto .form-box .form-group .icon-validator.ic-success span {
      background-image: url(../img/icon_validator_success.png);
      left: 100%;
      opacity: 0;
      animation: kf_pullFx_right 0.5s ease-in-out; }
    .contacto .form-box .form-group .icon-validator.ic-error span {
      background-image: url(../img/icon_validator_error.png);
      left: 100%;
      opacity: 0;
      animation: kf_pullFx_right 0.5s ease-in-out; }
  .contacto .form-box .form-group.success .icon-validator.ic-success span {
    left: 0%;
    left: 0;
    opacity: 1;
    animation: kf_pushFx_right 0.5s ease-in-out; }
  .contacto .form-box .form-group.error .icon-validator.ic-success span {
    left: 100%;
    opacity: 0;
    animation: kf_pullFx_right 0.5s ease-in-out; }
  .contacto .form-box .form-group.error .icon-validator.ic-error span {
    left: 0%;
    left: 0;
    opacity: 1;
    animation: kf_pushFx_right 0.5s ease-in-out; }

@media (min-width: 995px) {
  .contacto .form-box {
    padding: 3.2em 3.5em; } }

.contacto p.privacy {
  background-image: url(../img/icon_lock.png);
  background-position: 0.5em center;
  background-repeat: no-repeat;
  font-size: 0.85em;
  font-weight: lighter;
  padding-left: 2em;
  padding-top: 0.65em;
  margin-top: 0.5em;
  color: #aeaeae; }
  .contacto p.privacy a {
    color: #aeaeae; }

.white-font {
  color: #fff; }

.white-font:hover {
  color: #fff;
  transition: background-color .6s ease-in-out; }

.button-pad-5px {
  padding-top: 5px !important;
  padding-bottom: 5px !important; }

.centre {
  display: block;
  margin: auto; }

/*=> @ISLAND_EXPRESS
 *=======================================*/
@media (max-width: 620px) {
  .island_express .box-sec .bx-symbol {
    display: block;
    width: 100%;
    font-size: 4em !important;
    line-height: 0 !important;
    margin-top: -0.4em !important;
    padding: 0em .1em !important; }
  #bx-im-pc > div > span {
    margin-top: -0.3em !important; } }

body.island_express_hopper #prmtn-gnrl {
  margin-top: 0em; }

.title span {
  display: block !important;
  font-size: 65% !important;
  font-weight: normal !important; }

.element-hours {
  border-top: none !important; }

@media (max-width: 992px) {
  .element-hours {
    width: auto !important;
    display: inline-block; } }

.island_express #hero.wp-body {
  background-image: url(../img/island-express_bg-sm.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  height: auto; }
  @media (min-width: 768px) {
    .island_express #hero.wp-body {
      background-image: url(../img/island-express_bg.jpg);
      background-position: center -8em; } }

.island_express #hero {
  background-color: #17286d; }
  .island_express #hero .wp-main {
    padding: 4em 0em 0em 0em; }
  .island_express #hero .hero-box {
    color: #fff; }
    .island_express #hero .hero-box .hero-sub {
      color: #07829b;
      padding-bottom: 0.5em; }
    .island_express #hero .hero-box .hero-title {
      text-align: center;
      font-size: 4.2em;
      line-height: 110%;
      padding-bottom: 0.5em; }
      .island_express #hero .hero-box .hero-title span {
        font-weight: lighter;
        font-size: 0.8em;
        color: #fee600; }
    .island_express #hero .hero-box .phone {
      color: #fff;
      font-size: 2.3em;
      font-weight: lighter;
      line-height: 100%; }
    .island_express #hero .hero-box p {
      font-weight: lighter; }
    .island_express #hero .hero-box .buttons {
      padding-top: 1.2em; }
      .island_express #hero .hero-box .buttons a.btn-route {
        margin-right: 1em; }
  @media (min-width: 768px) {
    .island_express #hero .wp-main {
      padding: 6em 0em 4em 0em; }
    .island_express #hero .wp-main .hero-title {
      font-size: 2.8em; } }
  @media (min-width: 995px) {
    .island_express #hero .wp-main .hero-title {
      font-size: 3em; } }

.island_express .box-sec .bx {
  border: 1px solid #FFFFFF; }
  .island_express .box-sec .bx .bx-top {
    border-top: 1px solid #FFFFFF;
    border-bottom: 0px;
    padding-bottom: 0em; }
    @media (min-width: 995px) {
      .island_express .box-sec .bx .bx-top {
        border-bottom: 1px solid #FFFFFF;
        border-top: 0px;
        float: none;
        padding-bottom: 0.3em;
        width: auto; } }
  .island_express .box-sec .bx .bx-bottom {
    border-top: 1px solid #FFFFFF;
    padding-top: 0.65em; }
    @media (min-width: 995px) {
      .island_express .box-sec .bx .bx-bottom {
        border-top: 0px;
        float: none;
        padding-top: 1.3em;
        width: auto; } }

.island_express .box-sec .bx-left {
  border-width: 0px;
  padding-bottom: 4em;
  padding-top: 7.5em; }
  @media (min-width: 995px) {
    .island_express .box-sec .bx-left {
      border-right: 1px solid #FFFFFF;
      padding-bottom: 4em; } }

.island_express .box-sec .bx-right {
  border: 0px solid #FFFFFF;
  padding-bottom: 1em;
  padding-top: 0em; }
  @media (min-width: 995px) {
    .island_express .box-sec .bx-right {
      padding-bottom: 1em;
      padding-top: 2.5em; } }

.island_express .box-sec .bx-header {
  background-color: #17286d;
  background-image: url(../img/island-express_bg.jpg);
  background-position: -155px -436px;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2; }
  .island_express .box-sec .bx-header .title {
    background-image: none;
    font-size: 2em;
    font-weight: 100;
    margin: 0em 0em 0em 1em;
    padding: 0.8em 0em 0.8em 0em;
    text-transform: capitalize !important; }
    @media (min-width: 768px) {
      .island_express .box-sec .bx-header .title {
        background-image: url(../img/island_express-icon_ship_auto.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0.8em 0em 0.8em 5.7em; } }
    .island_express .box-sec .bx-header .title span {
      display: block;
      font-size: 65%;
      font-weight: normal; }

.island_express .box-sec .bx-body {
  margin-top: -2.6em;
  position: relative;
  z-index: 1; }

.island_express .box-sec .bx-title {
  color: #FFFFFF;
  font-weight: 100; }
  .island_express .box-sec .bx-title span {
    color: #fff;
    display: inline-block;
    font-size: 2em;
    padding-bottom: 0.15em; }

.island_express .box-sec .bx-item {
  float: left; }
  .island_express .box-sec .bx-item p {
    color: #7e90d7;
    font-size: 0.75em;
    line-height: 110%; }

.island_express .box-sec .bx-symbol {
  color: #FFFFFF;
  float: left;
  font-size: 1em;
  line-height: 2.7em;
  padding: 0em 0.9em; }

.island_express .box-sec .note {
  margin: 0.3em 0em 1.5em 0em;
  color: #7e90d7;
  font-size: 1em;
  line-height: 110%; }

.island_express .box-sec .price {
  margin-top: .25em;
  color: #fee600;
  font-size: 3em;
  font-weight: 100;
  margin-left: -0.1em;
  display: block !important; }
  @media (min-width: 768px) {
    .island_express .box-sec .price {
      font-size: 4em; } }
  .island_express .box-sec .price span {
    font-size: 0.65em; }

.island_express .box-sec .departures_tit {
  color: #FFFFFF;
  font-weight: lighter; }

.island_express .box-sec .departures {
  color: #FFFFFF; }

.island_express .box-sec .departures_list {
  color: #FFFFFF;
  font-weight: 100;
  line-height: 110%;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0; }

.island_express .box-sec-1 .bx-header {
  background-image: none; }

.island_express .box-sec-2 .bx-header {
  background-image: none; }

.island_express .box-sec.box-sec-1 .bx-left {
  background-image: url(../img/island-express_s1.png);
  background-position: right bottom;
  background-repeat: no-repeat; }

.island_express .box-sec.box-sec-2 .bx-left {
  background-image: url(../img/island-express_s2.jpg);
  background-position: right bottom;
  background-repeat: no-repeat; }

.island_express .go-island p {
  color: #7e90d7;
  font-size: 1.2em;
  font-weight: 100;
  line-height: 110%;
  text-align: center; }
  @media (min-width: 768px) {
    .island_express .go-island p {
      font-size: 1.5em; } }
  @media (min-width: 995px) {
    .island_express .go-island p {
      font-size: 2.05em; } }

/*=> @ISLAND_HOPPER
 *=======================================*/
@media (max-width: 768px) {
  #board-ferry .bx-item {
    width: 100%; } }

body.island_hopper #prmtn-gnrl {
  margin-top: 0em; }

.island_hopper #hero.wp-body {
  background-image: url(../img/island-hopper_bg.jpg);
  background-repeat: no-repeat;
  background-position: center -4em;
  height: auto; }

.island_hopper #hero {
  background-color: #17286d; }
  .island_hopper #hero .wp-main {
    padding: 4em 0em 0em 0em; }
  .island_hopper #hero .hero-box {
    color: #fff; }
    .island_hopper #hero .hero-box .hero-sub {
      color: #07829b;
      padding-bottom: 0.5em; }
    .island_hopper #hero .hero-box .hero-title {
      text-align: center;
      font-size: 4.2em;
      line-height: 110%;
      padding-bottom: 0.5em; }
      .island_hopper #hero .hero-box .hero-title span {
        font-weight: lighter;
        font-size: 0.8em;
        color: #fee600; }
  @media (min-width: 768px) {
    .island_hopper #hero .wp-main {
      padding: 6em 0em 4em 0em; }
    .island_hopper #hero .wp-main .hero-title {
      font-size: 2.8em; } }
  @media (min-width: 995px) {
    .island_hopper #hero .wp-main .hero-title {
      font-size: 3em; } }

.island_hopper .bx-footer-s {
  display: block;
  text-align: center;
  color: #7e90d7; }

.island_hopper .box-sec .bx {
  border: 1px solid #FFFFFF; }
  .island_hopper .box-sec .bx .bx-top {
    border-top: 1px solid #FFFFFF;
    border-bottom: 0px;
    padding-bottom: 0em; }
    @media (min-width: 995px) {
      .island_hopper .box-sec .bx .bx-top {
        border-bottom: 1px solid #FFFFFF;
        border-top: 0px;
        float: none;
        padding-bottom: 0.3em;
        width: auto; } }
  .island_hopper .box-sec .bx .bx-bottom {
    border-top: 1px solid #FFFFFF;
    padding-top: 0.65em; }
    @media (min-width: 995px) {
      .island_hopper .box-sec .bx .bx-bottom {
        border-top: 0px;
        float: none;
        width: auto; } }
    .island_hopper .box-sec .bx .bx-bottom .day {
      color: #fee600;
      font-size: 1.3em; }

.island_hopper .box-sec .bx-left {
  border-width: 0px;
  padding-bottom: 7.5em;
  padding-top: 7em; }
  @media (min-width: 995px) {
    .island_hopper .box-sec .bx-left {
      border-right: 1px solid #FFFFFF; } }

.island_hopper .box-sec .bx-right {
  border: 0px solid #FFFFFF;
  padding-bottom: 1em;
  padding-top: 0em; }
  @media (min-width: 995px) {
    .island_hopper .box-sec .bx-right {
      padding-bottom: 1em;
      padding-top: 2.5em; } }

.island_hopper .box-sec .bx-header {
  background-color: #17286d;
  position: relative;
  z-index: 2; }
  .island_hopper .box-sec .bx-header .title {
    background-image: none;
    font-size: 2em;
    font-weight: 100;
    margin: 0em 0em 0em 1em;
    padding: 0.8em 0em 0.8em 0em; }
    @media (min-width: 768px) {
      .island_hopper .box-sec .bx-header .title {
        background-image: url(../img/island_express-icon_ship_auto.png);
        background-position: left center;
        background-repeat: no-repeat;
        padding: 0.8em 0em 0.8em 5.7em;
        margin: 0em 0em 0em 1em;
        font-size: 2em; } }

.island_hopper .box-sec .bx-body {
  margin-top: -2.6em;
  position: relative;
  z-index: 1; }

.island_hopper .box-sec .bx-title {
  color: #FFFFFF;
  font-weight: 100; }
  .island_hopper .box-sec .bx-title span {
    color: #fee600;
    display: inline-block;
    font-size: 2em;
    padding-bottom: 0.15em; }
  .island_hopper .box-sec .bx-title span.sub {
    color: #FFFFFF;
    font-size: 2em;
    line-height: 80%; }
    .island_hopper .box-sec .bx-title span.sub small {
      display: inline;
      font-size: 0.5em; }
  .island_hopper .box-sec .bx-title span.bx-symbol {
    color: #FFFFFF; }

.island_hopper .box-sec .bx-item {
  position: relative;
  float: left; }
  .island_hopper .box-sec .bx-item .box {
    color: #FFFFFF;
    font-size: 1em; }
    .island_hopper .box-sec .bx-item .box .box-s {
      font-size: 1em;
      padding-top: 1em; }
  .island_hopper .box-sec .bx-item .tit {
    vertical-align: top;
    display: block; }
    @media (min-width: 768px) {
      .island_hopper .box-sec .bx-item .tit {
        display: inline-block; } }
  .island_hopper .box-sec .bx-item .info {
    font-size: 1em;
    color: #FFFFFF;
    font-weight: 100; }
    .island_hopper .box-sec .bx-item .info b {
      font-weight: 400; }
  .island_hopper .box-sec .bx-item .tit-s {
    font-size: 0.5em;
    color: #FFFFFF;
    font-weight: 100; }
    .island_hopper .box-sec .bx-item .tit-s b {
      font-weight: 400; }
  .island_hopper .box-sec .bx-item .info-s {
    font-size: 0.45em;
    color: #7e90d7; }
  .island_hopper .box-sec .bx-item .bx-symbol {
    font-size: 1em;
    font-weight: 400; }

.island_hopper .box-sec .bx-item-s {
  float: left; }

.island_hopper .box-sec .title-s {
  color: #FFFFFF;
  font-size: 3em;
  font-weight: 100;
  display: block; }

.island_hopper .box-sec .note {
  margin: 0.3em 0em 1.5em 0em;
  color: #7e90d7;
  font-size: 0.85em;
  line-height: 110%; }

.island_hopper .box-sec .price {
  color: #fee600;
  font-size: 3em;
  font-weight: 100;
  margin-left: -0.1em;
  line-height: 95%; }
  @media (min-width: 768px) {
    .island_hopper .box-sec .price {
      font-size: 3.5em; } }
  .island_hopper .box-sec .price span {
    font-size: 0.65em; }

.island_hopper .box-sec .departures {
  color: #FFFFFF; }

.island_hopper .box-sec .departures_list {
  color: #FFFFFF;
  font-weight: 100;
  line-height: 110%;
  list-style-type: none;
  padding: 0;
  margin-bottom: 0; }

.island_hopper .box-sec .button-outline {
  position: relative;
  top: 0em;
  right: 0em; }
  @media (min-width: 768px) {
    .island_hopper .box-sec .button-outline {
      position: absolute;
      top: 3.5em;
      right: -1.5em; } }

.island_hopper .box-sec .bx-symbol {
  font-size: 1em;
  float: left;
  line-height: 100%;
  padding: 0em 1em; }
  @media (max-width: 767px) {
    .island_hopper .box-sec .bx-symbol {
      display: none; } }

.island_hopper .box-sec.box-sec-1 .bx .bx-top {
  border-top: 0px solid #FFFFFF; }
  @media (min-width: 995px) {
    .island_hopper .box-sec.box-sec-1 .bx .bx-top {
      border-bottom: 0px solid #FFFFFF; } }

.island_hopper .box-sec.box-sec-1 .bx-right {
  border-top: 1px solid #FFFFFF; }
  @media (min-width: 995px) {
    .island_hopper .box-sec.box-sec-1 .bx-right {
      border-top: 0px;
      padding-bottom: 0em;
      padding-top: 0.75em; } }

.island_hopper .box-sec.box-sec-1 .bx-item.bx-one {
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 1em;
  margin-bottom: 1em; }
  @media (min-width: 768px) {
    .island_hopper .box-sec.box-sec-1 .bx-item.bx-one {
      border: 0px;
      padding-bottom: 0em;
      margin-bottom: 0em; } }

.island_hopper .box-sec.box-sec-1 .bx-symbol {
  border-left: 1px solid #FFFFFF;
  line-height: 4.3em;
  padding: 0em 0em 0em 2.7em;
  margin: 0.5em 0em 0em 2.7em; }

.island_hopper .box-sec.box-sec-1 .bx-header {
  background-image: url(../img/island-hopper_bg.jpg);
  background-position: -818px -528px;
  background-repeat: no-repeat; }
  @media (min-width: 768px) {
    .island_hopper .box-sec.box-sec-1 .bx-header {
      background-position: -636px -357px; } }
  @media (min-width: 995px) {
    .island_hopper .box-sec.box-sec-1 .bx-header {
      background-position: -557px -378px; } }
  @media (min-width: 1280px) {
    .island_hopper .box-sec.box-sec-1 .bx-header {
      background-position: -484px -391px; } }
  .island_hopper .box-sec.box-sec-1 .bx-header .title {
    background-image: none;
    margin: 0em 0em 0em 1em; }
    @media (min-width: 768px) {
      .island_hopper .box-sec.box-sec-1 .bx-header .title {
        background-image: url(../img/island_express-icon_ship.png);
        padding-left: 2em; } }

@media (min-width: 995px) {
  .island_hopper .box-sec.box-sec-alt .bx-right {
    padding-top: 3.5em; } }

.island_hopper .box-sec.box-sec-alt .bx-item {
  float: none; }

.island_hopper .box-sec.box-sec-alt .bx-item .tit {
  padding-right: 0.7em; }

.island_hopper .box-sec.box-sec-alt .bx-item .info {
  padding-top: 0.3em; }

.island_hopper .box-sec.box-sec-alt .bx-item.bx-one {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 2em;
  padding-bottom: 1.5em; }

.island_hopper .box-sec.box-sec-2 .bx-left {
  background-image: url(../img/island-hopper_s1.jpg);
  background-position: right bottom;
  background-repeat: no-repeat; }

.island_hopper .box-sec.box-sec-3 .bx-left {
  background-image: url(../img/island-hopper_s2.jpg);
  background-position: right bottom;
  background-repeat: no-repeat; }

.island_hopper .go-island p {
  color: #7e90d7;
  font-size: 1.2em;
  font-weight: 100;
  line-height: 110%;
  text-align: center; }
  @media (min-width: 768px) {
    .island_hopper .go-island p {
      font-size: 1.5em; } }
  @media (min-width: 995px) {
    .island_hopper .go-island p {
      font-size: 2.05em; } }

/*=> @INNOVATION
 *=======================================*/
body.innovation #prmtn-gnrl {
  margin-top: 0em; }

.innovation #hero.wp-body {
  background-image: url(../img/innovacion-hero_bg.jpg);
  background-color: #17286d;
  background-repeat: no-repeat;
  background-position: center top;
  height: auto;
  min-height: 480px; }
  @media (min-width: 768px) {
    .innovation #hero.wp-body {
      height: 640px; } }
  @media (min-width: 995px) {
    .innovation #hero.wp-body {
      height: 790px; } }

.innovation #hero .title {
  font-weight: 100; }
  .innovation #hero .title span.hd {
    background: #3db7da;
    color: #3db7da;
    font-size: 1.2em;
    letter-spacing: 0.35em;
    /* For Safari 5.1 to 6.0 */
    /* For Opera 11.1 to 12.0 */
    /* For Firefox 3.6 to 15 */
    background: linear-gradient(#3db7da, #46e2ca);
    /* Standard syntax */
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -o-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    text-fill-color: transparent; }
    @media (min-width: 768px) {
      .innovation #hero .title span.hd {
        font-size: 1.2em; } }
    @media (min-width: 995px) {
      .innovation #hero .title span.hd {
        font-size: 1.45em; } }
  .innovation #hero .title span.ft {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    color: #596aaf;
    font-size: 0.7em;
    position: relative;
    top: 0em; }
    @media (min-width: 768px) {
      .innovation #hero .title span.ft {
        top: -0.6em;
        font-size: 0.59em; } }
    @media (min-width: 995px) {
      .innovation #hero .title span.ft {
        font-size: 0.715em;
        top: -0.4em; } }

.innovation #hero p {
  color: #FFFFFF;
  font-size: 1.15em;
  font-weight: 100; }
  @media (min-width: 768px) {
    .innovation #hero p {
      font-size: 1.4em; } }
  @media (min-width: 995px) {
    .innovation #hero p {
      font-size: 1.7em; } }

.innovation #video.wp-body {
  background-image: url(../img/innovacion-video_bg.jpg);
  background-color: #17286d;
  background-repeat: no-repeat;
  background-position: center top;
  height: auto;
  min-height: 320px; }
  @media (min-width: 768px) {
    .innovation #video.wp-body {
      height: 480px; } }
  @media (min-width: 995px) {
    .innovation #video.wp-body {
      height: 596px; } }

.innovation #video-iframe {
  background-color: #17286d; }
  .innovation #video-iframe .videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0; }
  .innovation #video-iframe .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.innovation #slider.wp-body {
  background-image: url(../img/innovation-slides_bg.jpg);
  background-color: #17286d;
  background-repeat: no-repeat;
  background-position: center bottom;
  height: auto; }

.innovation #slider .sd-control-btn {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  width: auto;
  margin-bottom: 1.5em;
  position: relative;
  z-index: 9; }
  @media (min-width: 995px) {
    .innovation #slider .sd-control-btn {
      margin-bottom: 2.5em;
      width: 768px; } }
  @media (min-width: 1200px) {
    .innovation #slider .sd-control-btn {
      margin-bottom: 3em;
      width: 920px; } }
  @media (min-width: 1400px) {
    .innovation #slider .sd-control-btn {
      margin-bottom: 5em;
      width: 1200px; } }
  .innovation #slider .sd-control-btn .buticon {
    margin: 0 0.35em; }
    .innovation #slider .sd-control-btn .buticon:first-child {
      margin-left: 0em; }
    .innovation #slider .sd-control-btn .buticon:last-child {
      margin-right: 0em; }
    @media (min-width: 768px) {
      .innovation #slider .sd-control-btn .buticon {
        margin: 0 0.85em; } }
    @media (min-width: 995px) {
      .innovation #slider .sd-control-btn .buticon {
        margin: 0 1.1em; } }
    @media (min-width: 1200px) {
      .innovation #slider .sd-control-btn .buticon {
        margin: 0 2em; } }
    @media (min-width: 1400px) {
      .innovation #slider .sd-control-btn .buticon {
        margin: 0 3em; } }

.innovation #slider #innovation-slider {
  position: relative;
  z-index: 1; }

.innovation #slider .sd-pg #sd-image {
  position: relative;
  margin-top: 0em; }
  @media (min-width: 768px) {
    .innovation #slider .sd-pg #sd-image {
      margin-top: -6em; } }
  @media (min-width: 995px) {
    .innovation #slider .sd-pg #sd-image {
      margin-top: 4em;
      position: absolute; } }
  @media (min-width: 1200px) {
    .innovation #slider .sd-pg #sd-image {
      margin-top: 3em; } }
  .innovation #slider .sd-pg #sd-image img {
    margin: 0 auto 1.5em auto;
    width: 80%; }
    @media (min-width: 768px) {
      .innovation #slider .sd-pg #sd-image img {
        margin: 0 0;
        width: 100%; } }
    @media (min-width: 1200px) {
      .innovation #slider .sd-pg #sd-image img {
        width: auto; } }
  .innovation #slider .sd-pg #sd-image .sd-image-wp {
    background-color: #FFFFFF; }
    @media (min-width: 768px) {
      .innovation #slider .sd-pg #sd-image .sd-image-wp {
        background-color: transparent; } }
  .innovation #slider .sd-pg #sd-image #sd-image-wp {
    background-color: white; }
    @media (min-width: 768px) {
      .innovation #slider .sd-pg #sd-image #sd-image-wp {
        background-color: transparent; } }

.innovation #slider .sd-pg .sd-article {
  background-color: #FFFFFF;
  width: 100%;
  height: auto;
  text-align: left;
  padding: 2em 0;
  min-height: auto; }
  @media (min-width: 768px) {
    .innovation #slider .sd-pg .sd-article {
      padding: 2.7em 0 9em 0; } }
  .innovation #slider .sd-pg .sd-article .sd-hd {
    position: relative; }
    .innovation #slider .sd-pg .sd-article .sd-hd .sd-title-ico {
      display: none;
      background-position: left top;
      background-repeat: no-repeat;
      height: auto;
      position: absolute;
      top: 0;
      width: auto; }
      @media (min-width: 768px) {
        .innovation #slider .sd-pg .sd-article .sd-hd .sd-title-ico {
          display: inline-block;
          *display: inline;
          *zoom: 1; } }
  .innovation #slider .sd-pg .sd-article .title {
    display: inline-block;
    *display: inline;
    *zoom: 1;
    color: #17286d;
    font-size: 2em;
    font-weight: 100;
    margin-bottom: 0.5em;
    padding-left: 0; }
    @media (min-width: 768px) {
      .innovation #slider .sd-pg .sd-article .title {
        margin-bottom: 1.15em; } }
  .innovation #slider .sd-pg .sd-article p {
    color: #17286d;
    margin-top: 1em;
    margin-bottom: 0;
    font-weight: 100;
    line-height: 110%; }
    @media (min-width: 768px) {
      .innovation #slider .sd-pg .sd-article p {
        margin-top: 0em;
        line-height: 1.4625; } }

.innovation #slider .sd-pg#sd-pg-1 .sd-title-ico {
  background-image: url(../img/innovation-slides_diseno_ico.png);
  width: 55px;
  height: 55px; }

@media (min-width: 768px) {
  .innovation #slider .sd-pg#sd-pg-1 .sd-article .title {
    padding-left: 70px; } }

@media (min-width: 995px) {
  .innovation #slider .sd-pg#sd-pg-1 .sd-article {
    padding-bottom: 11em; } }

@media (min-width: 1200px) {
  .innovation #slider .sd-pg#sd-pg-1 .sd-article {
    padding-bottom: 10em; } }

@media (min-width: 1400px) {
  .innovation #slider .sd-pg#sd-pg-1 .sd-article {
    padding-bottom: 13em; } }

.innovation #slider .sd-pg#sd-pg-2 .sd-title-ico {
  background-image: url(../img/innovation-slides_comodidad_ico.png);
  width: 80px;
  height: 60px;
  top: -8px; }

@media (min-width: 768px) {
  .innovation #slider .sd-pg#sd-pg-2 .sd-article .title {
    padding-left: 95px; } }

@media (min-width: 995px) {
  .innovation #slider .sd-pg#sd-pg-2 .sd-article {
    padding-bottom: 7em; } }

@media (min-width: 1200px) {
  .innovation #slider .sd-pg#sd-pg-2 .sd-article {
    padding-bottom: 4em; } }

@media (min-width: 1400px) {
  .innovation #slider .sd-pg#sd-pg-2 .sd-article {
    padding-bottom: 9em; } }

.innovation #slider .sd-pg#sd-pg-3 .sd-title-ico {
  background-image: url(../img/innovation-slides_equipamento_ico.png);
  width: 68px;
  height: 62px;
  top: -10px; }

@media (min-width: 768px) {
  .innovation #slider .sd-pg#sd-pg-3 .sd-article .title {
    padding-left: 93px; } }

@media (min-width: 995px) {
  .innovation #slider .sd-pg#sd-pg-3 .sd-article {
    padding-bottom: 7em; } }

@media (min-width: 1200px) {
  .innovation #slider .sd-pg#sd-pg-3 .sd-article {
    padding-bottom: 6em; } }

@media (min-width: 1400px) {
  .innovation #slider .sd-pg#sd-pg-3 .sd-article {
    padding-bottom: 10em; } }

.innovation #slider .sd-pg#sd-pg-4 .sd-title-ico {
  background-image: url(../img/innovation-slides_1eraclase_ico.png);
  width: 50px;
  height: 79px;
  top: -8px; }

@media (min-width: 768px) {
  .innovation #slider .sd-pg#sd-pg-4 .sd-article .title {
    padding-left: 65px; } }

@media (min-width: 995px) {
  .innovation #slider .sd-pg#sd-pg-4 .sd-article {
    padding-bottom: 7em; } }

@media (min-width: 1200px) {
  .innovation #slider .sd-pg#sd-pg-4 .sd-article {
    padding-bottom: 6em; } }

@media (min-width: 1400px) {
  .innovation #slider .sd-pg#sd-pg-4 .sd-article {
    padding-bottom: 10em; } }

.innovation #slider .banner-txt {
  color: #7e90d7;
  font-size: 1.2em;
  font-weight: 100;
  line-height: 110%;
  text-align: center; }
  @media (min-width: 768px) {
    .innovation #slider .banner-txt {
      font-size: 1.5em; } }
  @media (min-width: 995px) {
    .innovation #slider .banner-txt {
      font-size: 2.05em; } }

/*=> @PROFILE
 *=======================================*/
.profile-container {
  padding-top: 2.5em; }

.bx-block {
  background-color: #f7f7f7;
  margin: 1.2em .8em; }
  .bx-block .bx-block-header {
    background-color: #e8e8e8;
    padding: .6em 1em;
    color: #17286d;
    font-size: 1.2em; }
  .bx-block .bx-block-content {
    padding: 1.5em 1em;
    /*.bx-address
      @media ( min-width:992px)
        display: flex
        flex-flow: row*/ }
    .bx-block .bx-block-content .profile-bx-titlea {
      color: #07829b;
      font-size: 1.8em;
      padding-top: 1.5em; }
    .bx-block .bx-block-content .profile-bx-titleb {
      color: #07829b;
      font-size: 1.2em; }
    .bx-block .bx-block-content .bx-content-title {
      color: #07829b;
      padding-left: 10px; }
    .bx-block .bx-block-content .bx-content-text {
      color: #283a83;
      padding-left: 10px; }
    .bx-block .bx-block-content .btn-center {
      display: block;
      margin: auto;
      padding-top: 1.8em; }
    .bx-block .bx-block-content .div-line {
      border-bottom: 1px solid silver; }
      @media (max-width: 766px) {
        .bx-block .bx-block-content .div-line {
          margin: 10px 20px; } }
      @media (min-width: 992px) {
        .bx-block .bx-block-content .div-line {
          margin: 20px 10px; } }

.bx-centered {
  display: block;
  margin: auto;
  text-align: center; }

.bx-centered2 {
  margin: auto; }

.head-btn {
  display: none; }
  @media (max-width: 988px) {
    .head-btn {
      display: block; } }

.foot-btn {
  display: block; }
  @media (max-width: 988px) {
    .foot-btn {
      display: none; } }

.message-block ul li {
  list-style-type: none !important; }

.rewards-title {
  color: #17286d;
  font-size: 1.2em;
  font-weight: bold; }

.rewards-pts {
  color: #07829b;
  font-size: 1.5em;
  font-weight: bold; }

.rewards-change-title {
  color: #17286d;
  font-weight: bold; }

.rewards-success {
  color: #5cb85c;
  font-weight: bold;
  font-size: 1.4em;
  text-align: center; }

.rewards-failure {
  color: #b94a48;
  font-size: 1.4em;
  font-weight: bold;
  list-style-type: none !important; }

.rewards-error {
  color: #b94a48;
  font-weight: bold; }

/*=> @LOGIN
 *=======================================*/
.login strong.hero-sub {
  color: #07829b; }

.login .form-box label {
  font-weight: normal;
  color: #54596f; }

.login .form-box input.form-control {
  border-bottom: 1px solid #54596f;
  color: #54596f; }
  .login .form-box input.form-control:hover, .login .form-box input.form-control:focus {
    color: #07829b;
    border-bottom: 1px solid #07829b; }

.login .form-box .form-control:hover:-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control:hover::-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control:hover:-ms-input-placeholder {
  color: #07829b; }

.login .form-box .form-control:hover::-webkit-input-placeholder {
  color: #07829b; }

.login .form-box .form-control:focus:-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control:focus::-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control:focus:-ms-input-placeholder {
  color: #07829b; }

.login .form-box .form-control:focus::-webkit-input-placeholder {
  color: #07829b; }

.login .form-box .form-control:-moz-placeholder {
  color: #54596f; }

.login .form-box .form-control::-moz-placeholder {
  color: #54596f; }

.login .form-box .form-control:-ms-input-placeholder {
  color: #54596f; }

.login .form-box .form-control::-webkit-input-placeholder {
  color: #54596f; }

.login .form-box input.form-control.error,
.login .form-box textarea.form-control.error {
  border-bottom: 1px solid #07829b;
  color: #07829b; }

.login .form-box .form-control.error:-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control.error::-moz-placeholder {
  color: #07829b; }

.login .form-box .form-control.error:-ms-input-placeholder {
  color: #07829b; }

.login .form-box .form-control.error::-webkit-input-placeholder {
  color: #07829b; }

/*=> @ERRORS
 *=======================================*/
.error-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center; }
  .error-container .box-body {
    font-size: 18px;
    background-color: #fff; }
    @media (min-width: 280px) {
      .error-container .box-body {
        padding: 40px 50px;
        margin: 50px 10px; } }
    @media (min-width: 768px) {
      .error-container .box-body {
        padding: 40px 50px;
        margin: 100px; } }
    @media (min-width: 992px) {
      .error-container .box-body {
        padding: 60px 80px;
        margin: 100px; } }
    @media (min-width: 1200px) {
      .error-container .box-body {
        padding: 60px 80px;
        margin: 100px; } }
    .error-container .box-body .error-description {
      color: #777;
      font-size: 150%; }

@media (min-width: 280px) {
  .sy-msg {
    font-size: 40px; } }

@media (min-width: 768px) {
  .sy-msg {
    font-size: 40px; } }

@media (min-width: 992px) {
  .sy-msg {
    font-size: 50px; } }

@media (min-width: 1200px) {
  .sy-msg {
    font-size: 60px; } }

.box-error {
  position: relative;
  text-align: center; }
  .box-error .error_title {
    position: absolute;
    color: #b94a48; }
    @media (min-width: 280px) {
      .box-error .error_title {
        font-size: 45px; } }
    @media (min-width: 768px) {
      .box-error .error_title {
        font-size: 45px; } }
    @media (min-width: 992px) {
      .box-error .error_title {
        font-size: 55px; } }
    @media (min-width: 1200px) {
      .box-error .error_title {
        font-size: 70px; } }
  .box-error .error_code {
    font-family: 'Montserrat', sans-serif;
    color: #b94a48; }
    @media (min-width: 280px) {
      .box-error .error_code {
        font-size: 150px; } }
    @media (min-width: 768px) {
      .box-error .error_code {
        font-size: 150px; } }
    @media (min-width: 992px) {
      .box-error .error_code {
        font-size: 180px; } }
    @media (min-width: 1200px) {
      .box-error .error_code {
        font-size: 220px; } }

/*# sourceMappingURL=styles.css.map */
